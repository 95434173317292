import React, { useEffect, useState, useCallback } from 'react'
import { useForm } from 'react-hook-form';
import { Button, Checkbox, Label, Modal, Select, Spinner, TextInput } from 'flowbite-react';
import PublishHeader from '../components/PublishHeader';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { HOST, configuration, QuickNodeUrl } from '../utils/constants'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection, PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js'
import {
  getOrCreateAssociatedTokenAccount, getAccount,
  createAssociatedTokenAccountInstruction,
  getAssociatedTokenAddress,
  createTransferInstruction,
  TOKEN_PROGRAM_ID
} from "@solana/spl-token"

const PublishModal = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [verifying, setVerifying] = useState(null)
  const [dataToSend, setdataToSend] = useState(null)
  const [ShowSummary, setShowSummary] = useState(false)
  const [clientData, setClientData] = useState(null)
  const [sendingTransac, setSendingTransac] = useState(false)
  const [inCludeF, setInCludeF] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [theText, settheText] = useState("Sending Payment...")
  const [solRaidPrices, setSolraidPrices] = useState(null)
  const [boopRaidPrices, setBoopRaidPrices] = useState(null)

  const [thesummary, setThesummary] = useState({
    raids: 0,
    follows: false,
    payment: 0,
    boopPayment: 0
  })


  let { publicKey, connected, disconnect, sendTransaction } = useWallet();
  const SOLANA_CONNECTION = new Connection(QuickNodeUrl)

  useEffect(() => {
    setInCludeF(false)
    setVerifying(false)
    setSendingTransac(false)
    settheText("Sending Payment...")
    document.documentElement.classList.add("dark")
  }, [])

  const onSubmit = data => {
    setVerifying(true)
    if (inCludeF === true) {
      data.follow = true
    } else {
      data.follow = false
    }
    data.clientID = clientData.address
    data.min_characters = 0
    data.min_followers = 0
    console.log(data);

    setTimeout(() => {
      setVerifying(false)

      setdataToSend(data)
      setModalOpen(true)

    }, 1000);

  };

  async function publishTweet(huntCurr) {
    settheText("Publishing your tweet !")
    let finalData = { ...dataToSend, huntcurrency: huntCurr, solPaid: thesummary.payment, boopPaid: thesummary.boopPayment }
    // console.log(finalData)
    try {
      const response = await axios.post(`${HOST}/auth/publishHunt`, finalData, configuration)

      const thdata = response.data
      if (thdata.status === 0) {
        toast.info(thdata.message, {
          autoClose: 3000,
          theme: 'colored'
        })

      } else {
        // console.log(thdata)
        setModalOpen(false)
        setSendingTransac(false)
        toast.success("Your raid request has been received 🎉!", {
          autoClose: 3000,
          theme: 'colored'
        })
        // console.log(thdata)
        setTimeout(() => {
          navigate("/publish")
        }, 2000);
      }

    } catch (error) {
      console.log(error)
      setSendingTransac(false)
      toast.info("Something went wrong while publishing your tweet! Open a ticket and report this.", {
        autoClose: 6000,
        theme: 'colored'
      })
    }
  }

  function followsChange(e) {
    if (e.target.checked) {
      setInCludeF(prevState => !prevState)

      let normalPay = solRaidPrices.price[thesummary.raids]
      let finalPay = normalPay * 2
      let normalPay2 = boopRaidPrices.price[thesummary.raids]

      setThesummary({ ...thesummary, payment: finalPay, boopPayment: normalPay2 * 2 })
    } else {
      setInCludeF(false)
      setThesummary({ ...thesummary, payment: solRaidPrices.price[thesummary.raids], boopPayment: boopRaidPrices.price[thesummary.raids] })
    }
  }

  function selectRaid(e) {
    setShowSummary(true)

    if (inCludeF === true) {
      let normalPay = solRaidPrices.price[e.target.value]
      let finalPay = normalPay * 2

      let normalPay2 = boopRaidPrices.price[e.target.value]
      setThesummary({ ...thesummary, payment: finalPay, raids: e.target.value, boopPayment: normalPay2 * 2 })

    } else {
      setThesummary({
        ...thesummary, payment: solRaidPrices.price[e.target.value],
        raids: e.target.value, boopPayment: boopRaidPrices.price[e.target.value]
      })
    }


  }

  const onClick = useCallback(async () => {
    try {
      setSendingTransac(true)
      settheText("Sending Payment...")
      if (!publicKey) throw new WalletNotConnectedError();
      SOLANA_CONNECTION.getBalance(publicKey).then((bal) => {
        console.log(bal / LAMPORTS_PER_SOL);

      });

      let lamportsI = LAMPORTS_PER_SOL * thesummary.payment;

      // console.log(publicKey.toBase58());
      // console.log("lamports sending: {}", lamportsI)
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey("9Y8TbTVfNcPPhfRerDhLQdGm2JHXaKKSLBYwLKvEqKvP"),
          lamports: lamportsI,
        })
      );


      const signature = await sendTransaction(transaction, SOLANA_CONNECTION);

      await SOLANA_CONNECTION.confirmTransaction(signature, 'confirmed');
      console.log(signature)
      // setSendingTransac(false)
      publishTweet('sol')
    } catch (error) {
      setSendingTransac(false)
      console.log(error)
      toast.warning("Something went wrong, Try again!", {
        autoClose: 2000,
        theme: 'colored'
      })
    }


  }, [publicKey, sendTransaction, SOLANA_CONNECTION]);

  const payBoop = async function () {

    try {
      setSendingTransac(true)
      settheText("Sending Payment...")
      if (!publicKey) throw new WalletNotConnectedError();

      const toPublicKey = new PublicKey("9Y8TbTVfNcPPhfRerDhLQdGm2JHXaKKSLBYwLKvEqKvP")
      const mint = new PublicKey('CCRJohp9bfQdhZCvCe7MzUQuXMJkKBi4XCjUq3A2YqN8')

      const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
        SOLANA_CONNECTION,
        publicKey,
        mint,
        publicKey,
        // signTransaction
      )

      const toTokenAccount = await getOrCreateAssociatedTokenAccount(
        SOLANA_CONNECTION,
        publicKey,
        mint,
        toPublicKey,
        // signTransaction
      )

      const transaction = new Transaction().add(
        createTransferInstruction(
          fromTokenAccount.address,
          toTokenAccount.address,
          publicKey,
          // 1 * Math.pow(10, 10),
          thesummary.boopPayment * Math.pow(10, 10)
        )
      )

      const signature = await sendTransaction(transaction, SOLANA_CONNECTION)

      const response = await SOLANA_CONNECTION.confirmTransaction(signature, 'confirmed')
      console.log('response', response)
      publishTweet('boop')

    } catch (error) {
      setSendingTransac(false)
      console.log(error)
      toast.warning("Something went wrong, Try again!", {
        autoClose: 2000,
        theme: 'colored'
      })
    }



  }


  const objKeys = [];
  useEffect(() => {
    async function fetchPayment() {
      try {
        const response = await axios.get(`${HOST}/auth/getHuntPrices`, configuration)
        const priceData = response.data

        let solPrice = priceData.filter(prii => prii.type === "sol" && prii.huntType === "raid")[0]
        let boopPrice = priceData.filter(prii => prii.type === "boop" && prii.huntType === "raid")[0]

        setBoopRaidPrices(boopPrice)
        setSolraidPrices(solPrice)

        for (let key in solPrice.price) {
          objKeys.push(key);
        }

        // console.log(solPrice, boopPrice)
      } catch (error) {
        console.log(error)
      }
    }

    fetchPayment()

  }, [])


  useEffect(() => {
    async function checkingUser() {
      try {
        const response = await axios.get(`${HOST}/auth/checkClient?clientId=${publicKey.toBase58()}`, configuration)
        const userData = response.data
        // console.log(userData)

        if (userData.status === 1) {
          setClientData(userData.data)

          // fetcHunts(publicKey.toBase58())

        } else {
          setClientData(userData.data)
          // fetcHunts(publicKey.toBase58())
        }

      } catch (err) {
        console.log(err, ' error finding user in DB')
      }
    }

    if (publicKey) {
      checkingUser()
    }

  }, [connected])

  return (
    <div className='px-[5%] mb-10'>
      <ToastContainer />
      <PublishHeader clientData={clientData} />

      <Modal
        show={modalOpen}
        size="lg"
        popup={true}
        onClose={() => setModalOpen(false)}
      >
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            {/* 010612 */}
            <div className="bg-[#010612] rounded-lg p-3 mb-5">
              <h1 className='text-left fontBold'>Tweet Url :</h1>
              <a
                href={dataToSend && dataToSend.tweet_url}
                rel='noreferrer'
                className='fontBold text-left'
                target='_blank'>{dataToSend && dataToSend.tweet_url}</a>


            </div>

            <div className='form-group p-3 bg-[#010612] text-white rounded-lg'>
              <div className='grid grid-cols-3'>
                <div>
                  <p className='text-center text-gray-400 text-lg'>Total Raids</p>
                  <p className='text-center fontBold'>{thesummary.raids}</p>
                </div>

                {inCludeF && (
                  <div>
                    <p className='text-center text-gray-400 text-lg'>Followers</p>
                    <p className='text-center fontBold'>{thesummary.raids}</p>
                  </div>
                )}


                <div>
                  <p className='text-center text-gray-400 text-lg'>Amount to Pay</p>
                  {/* <p className='text-center fontBold'>{thesummary.payment} sol</p> */}

                  <p className='text-center fontBold'>{thesummary.payment} sol or {thesummary.boopPayment} $BOOP</p>
                </div>

              </div>
            </div>


            <div className="flex justify-center gap-4 mt-10">
              {sendingTransac ? (
                <div>
                  <div className='flex justify-center'>
                    <Spinner size="lg" />
                  </div>
                  <p className='text-white fontBold'>{theText}.</p>
                </div>
              ) : (
                <>
                  <button
                    className='button text-white'
                    color="failure"
                    onClick={onClick}
                  // onClick={() => publishTweet("sol")}
                  >
                    Pay {thesummary.payment} SOL
                  </button>

                  <button
                    className='button text-white'
                    color="failure"
                    onClick={payBoop}
                  // onClick={() => publishTweet("boop")}
                  >
                    Pay {thesummary.boopPayment} $BOOP
                  </button>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {clientData ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-10">

            <div className='py-3 h-full w-full flex flex-row justify-between items-center'>
              <div className='mb-5'>
                <h1 className='text-2xl fontBold text-white'>Submit your Tweet</h1>
                <p className='fontBold text-gray-400'>Provide the information below to publish a hunt</p>
              </div>

              <div className='flex justify-end'>
                <button className='button text-white h-fit' onClick={() => navigate("/publish")}>Go Back</button>
              </div>
            </div>

            <div className='form-group mb-5'>
              <Label className='fontBold' value='Tweet Url' />
              <TextInput placeholder='https://twitter.com/Username/status/XXXXXXXXXXXXXXX' type="text" required {...register("tweet_url")} />
              <p className='text-gray-400 fontBold text-xs mt-1'>Enter the Url of your tweet. (eg, 'https://twitter.com/Username/status/XXXXXXXXXXXXXXX' )
              </p>
            </div>

            <div className="form-group mt-5 mb-5">
              <Label className='fontBold text-xl' value='Set requirements (Optional)' />
              <hr className="border-gray-500 mt-2" />

              <div className="mt-5 form-group bg-[#263250] px-3 rounded-lg py-3">
                <div className="mb-2">
                  <Label value='Min Characters on comments' className="fontBold" />
                  <TextInput size="sm" placeholder='0' type="number" {...register("min_characters")} />
                </div>


                {/* <div className="mb-2">
                  <Label value='Min Followers of raiders' className="fontBold" />
                  <TextInput placeholder='0' type="number" required {...register("min_followers")} />
                </div> */}

                <div className="mb-2">
                  <Label
                    value='Add the keyword you want our raiders to include in their comments'
                    className="fontBold mb-1" />
                  <TextInput placeholder='#HasukiFriday,GM' type="text" {...register("words_comment")} />
                </div>

              </div>

            </div>

            <div className='form-group mb-5'>
              <Label className='fontBold' value='Refferal Code (Optional)' />
              <TextInput type="text" {...register("ref_code")} />
              <p className='text-gray-400 fontBold text-sm mt-1'>Enter refferal code.</p>
            </div>

            <div className='form-group mb-2 mt-5'>
              <Label className='fontBold' value='Raid Budget' />
              <Select defaultValue={'DEFAULT'} required {...register("budget")} onChange={selectRaid} id="theRaidSe">
                <option style={{ color: '#fff !important' }} disabled value="DEFAULT">how many raids do you want ?</option>
                {solRaidPrices && (
                  <>
                    {Object.keys(solRaidPrices.price).map((key, index) => (
                      <option key={index} value={key}>{key} raids - {solRaidPrices.price[key]} SOL | {boopRaidPrices.price[key]} $BOOP </option>
                      // <option key={index} value={key}>{key} raids - {solRaidPrices.price[key]} SOL</option>

                    ))}
                  </>
                )}
              </Select>
              <p className='text-gray-300 fontBold mt-1'>1 raid includes (like,comment & retweet).</p>
            </div>

            <div className='form-group space-x-2 mb-5'>
              <Checkbox id='inFollows' onChange={followsChange} checked={inCludeF} />
              <Label value='Include Follows' htmlFor='inFollows' className='fontBold' />

            </div>

            {ShowSummary && (
              <div className='form-group p-3 bg-[#181F31] text-white rounded-lg'>
                <div className='grid grid-cols-3'>
                  <div>
                    <p className='text-center text-gray-400 text-lg'>Total Raids</p>
                    <p className='text-center fontBold'>{thesummary.raids}</p>
                  </div>

                  {inCludeF && (
                    <div>
                      <p className='text-center text-gray-400 text-lg'>Followers</p>
                      <p className='text-center fontBold'>{thesummary.raids}</p>
                    </div>
                  )}


                  <div>
                    <p className='text-center text-gray-400 text-lg'>Amount to Pay</p>
                    {/* <p className='text-center fontBold'>{thesummary.payment} SOL </p> */}

                    <p className='text-center fontBold'>{thesummary.payment} SOL or {thesummary.boopPayment} $BOOP</p>
                  </div>

                </div>
              </div>
            )}

            <div className='flex justify-center mt-5'>
              {verifying ? (
                <div>
                  <div className='flex justify-center'>
                    <Spinner size="lg" />
                  </div>
                  <p className='text-white fontBold'>Verifying Info....</p>
                </div>
              ) : (
                <button className='button text-white' style={{ backgroundColor: "#1DA1F2" }}>Submit Tweet!!</button>
              )}

            </div>

          </form>
        </>
      ) : (
        <div className="flex justify-center">
          <Spinner size="lg" />
        </div>
      )
      }


    </div >
  )
}

export default PublishModal