import React, { useState, useEffect } from 'react'
import { CiBitcoin } from "react-icons/ci";
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import { HOST, configuration } from '../utils/constants'
import { useSelector } from 'react-redux'
import { Select, Spinner, Tabs } from 'flowbite-react';
import { Header, Sidebar } from "../components";
import { DarkModeProvider } from '../context/DarkModeContext'
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { AiOutlineFileDone } from 'react-icons/ai'
import { GiJumpingDog } from 'react-icons/gi'
import { useWallet } from '@solana/wallet-adapter-react';

const Hunts = () => {
  let navigate = useNavigate();
  const { userInfo } = useSelector(
    (state) => state.user
  )
  const [HuntsData, setHuntsData] = useState(null)
  const [HuntInfo, setHuntInfo] = useState(null)
  const [ExpiredData, setExpiredData] = useState(null)


  let { connected } = useWallet();


  useEffect(() => {
    async function getHunts() {
      try {
        const response = await axios.get(`${HOST}/auth/retriveHunts`, configuration)


        const data = response.data.data

        let expiredData = data.filter(dto => dto.isExpired === true)
        let dataToSend = data.filter(dtt => dtt.isExpired !== true)

        setExpiredData(expiredData.reverse())
        setHuntsData(dataToSend)

        const response2 = await axios.get(`${HOST}/auth/retriveClaimedLive?address=${userInfo?.address}`, configuration)
        setHuntInfo(response2.data.data)

      } catch (error) {
        console.log(error)
      }

    }
    getHunts()
    let filterBtns = document.querySelectorAll(".filterBtn")
    filterBtns.forEach((filterBtn, i) => {

      filterBtn.classList.remove("active")
      filterBtn.addEventListener("click", function () {
        filterBtn.classList.add("active")
      })
    })
  }, [connected])

  function toggleCompleted(e) {
    if (e.target.value === "All") {
      let huntsCards = document.querySelectorAll(".huntsCard")
      huntsCards.forEach(huntsCard => {
        huntsCard.style.display = "block"
      })
    } else {
      let huntsCards = document.querySelectorAll(".huntsCard")
      huntsCards.forEach(huntsCard => {
        let claimedButton = huntsCard.querySelector(".claimedButton")
        if (claimedButton) {
          huntsCard.style.display = "none"
        } else {
          huntsCard.style.display = "block"
        }
      })
    }

  }

  function toggleSelectChain(e) {
    let huntsCards = document.querySelectorAll(".huntsCard")
    if (e.target.value === "") {
      huntsCards.forEach(huntsCard => {
        huntsCard.style.display = "block"
      })
    } else if (e.target.value === "Solana") {
      huntsCards.forEach(huntsCard => {
        huntsCard.style.display = "block"
      })
      let allBoopCont = document.querySelectorAll(`[data-reward="token"]`)
      allBoopCont.forEach(allBoopCon => {
        allBoopCon.style.display = "none"
      })
    } else {
      huntsCards.forEach(huntsCard => {
        huntsCard.style.display = "block"
      })
      let allBoopCont = document.querySelectorAll(`[data-reward="sol"]`)
      allBoopCont.forEach(allBoopCon => {
        allBoopCon.style.display = "none"
      })
    }
  }

  function handleNavigate(hunt_id, isCompleted) {
    if (isCompleted) {

    } else {
      navigate(`/hunt/${hunt_id}`)
    }

  }

  return (
    <DarkModeProvider>
      <Header />
      <ToastContainer />
      <section className="h-screen flex relative dark:bg-[#181F31]">
        <Sidebar />

        <section className='h-full lg:pl-[320px] w-full mt-24 px-[5%] dark:text-gray-300'>
          <div className='flex sm:flex-row flex-col sm:items-center justify-between w-full'>

            <p className='headings'><GiJumpingDog style={{ display: 'inline-block' }} size={33} /> Hunts</p>

            <div className='mt-4'>
              <p className='text-sm fontBold mb-2'>Filter by</p>
              <div className='flex items-center'>
                <div className='flex items-center space-x-4 w-full'>
                  <Select onChange={toggleCompleted} style={{ backgroundColor: '#0F172A', borderRadius: 50 }} className="sm:w-[200px] w-full">
                    <option value="All">All</option>
                    <option value="Not Completed" selected>Not Completed</option>
                  </Select>
                  <Select onChange={toggleSelectChain} style={{ backgroundColor: '#0F172A', borderRadius: 50 }} className="sm:w-[200px] w-full">
                    <option value="">Reward type</option>
                    <option value="Solana">$Sol</option>
                    <option value="$Boop">$Boop</option>
                    <option value="bonk">$bonk</option>
                  </Select>
                </div>
              </div>

            </div>

          </div>

          <Tabs.Group aria-label="Tabs with icons" className='mt-5' style="underline" id="tabsss">
            <Tabs.Item active={true} title={`Live Hunts (${HuntsData && HuntsData.length})`} icon={GiJumpingDog}>
              {HuntsData ? (
                <>
                  {HuntsData.length ? (
                    <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-5 gap-8">
                      {HuntsData.map((huntsdata, i) => (
                        <HuntsCard
                          key={i}
                          huntInfo={huntsdata}
                          claimsInfo={HuntInfo && HuntInfo}
                          handleNavigate={handleNavigate}
                          userInfo={userInfo}
                        />
                      ))}
                    </section>
                  ) : (
                    <div className='w-full flex items-center justify-center'>
                      <div>
                        <div className='flex justify-center'>
                          <img src={require("../assets/img/hasukibg1.png")} className="w-[300px]" alt="" />
                        </div>
                        <p className='fontBold text-xl text-center mt-4 textPrimary'>No Hunts Available Now !</p>
                      </div>
                    </div>
                  )}


                </>

              ) : (
                <div className='flex w-full justify-center items-center h-[40vh]'>
                  <Spinner size="lg" />
                </div>
              )}
            </Tabs.Item>
            <Tabs.Item title={`Past Hunts (${ExpiredData && ExpiredData.length})`} icon={AiOutlineFileDone}>
              <section >
                <h1 className='text-2xl fontBold mb-2'>Past Hunts</h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-5 gap-8">
                  {ExpiredData && ExpiredData.map((expiredDat, i) => (
                    <ExpiredCard
                      key={"ds" + i}
                      huntInfo={expiredDat}
                    />
                  ))}
                </div>
              </section>
            </Tabs.Item>
          </Tabs.Group>






          <h1 className='headings mt-5'>Daily Rewards</h1>

          <section className="grid grid-cols-3 mt-5 gap-14 pb-10">
            <PfpCard />
          </section>

        </section>
      </section>
    </DarkModeProvider >
  )
}

const HuntsCard = ({ handleNavigate, huntInfo, userInfo, claimsInfo }) => {

  let isCompleted = false

  if (userInfo && claimsInfo) {

    let claimed = claimsInfo.find(cc => cc.tweet_id === huntInfo.tweet_id)
    let claimed2 = claimsInfo.find(cc => cc.twitter_username === userInfo.twitterName)
    // console.log(claimed, claimed2)


    if (claimed && claimed2) {
      isCompleted = true
    } else {
      isCompleted = false
    }
  }

  return (
    <>
      <div className={`huntsCard w-full cursor-pointer rounded-xl ${isCompleted ? 'hidden' : 'block'}`} data-reward={huntInfo.reward_type} onClick={() => handleNavigate(huntInfo._id, isCompleted)}>
        <div className='w-full h-[120px] max-h-[120px] overflow-hidden rounded-t-xl p-3'>
          <img src={huntInfo.hunt_image} className='object-cover h-full w-full rounded-lg' />

        </div>
        <div className='p-4'>
          <p className='title text-lg fontBold text-[#fa6002]'>{huntInfo.hunt_title}</p>
          <div className='infoWrapper h-[80px]'>
            <p className='text-sm huntInfo text-[#ebe6e6]'>{huntInfo.hunt_description}</p>
          </div>

          <div className='flex justify-between'>
            <div>
              <p className='fontBold'>Claims</p>
              <p className='fontBold text-sm textPrimary'>{huntInfo.total_claimed}
                <span className='text-white'> / </span>
                {huntInfo.claimable}</p>
            </div>
            <div>
              <p className='fontBold'>Reward</p>
              {(huntInfo.reward_type === "sol") ? (
                <div className='flex items-center gap-1'>
                  <img src={require(`../assets/img/${huntInfo.reward_type}.png`)} className='h-[25px] w-[30px]' alt='' />
                  <p className='font-bold uppercase textPrimary text-sm'>{huntInfo.token_reward} ${huntInfo.reward_type}</p>
                </div>

              ) : (
                <div className='flex items-center gap-1'>
                  <CiBitcoin className='text-[#fa6002] text-lg' size={20} />
                  <p className='font-bold textPrimary text-sm'>{huntInfo.token_reward} ${huntInfo.reward_type.toUpperCase()}</p>
                </div>
              )}
            </div>
          </div>

          <div className='flex justify-between items-center mt-5'>
            {isCompleted && (
              <button className='bg-[#BCF0DA] px-4 py-1 rounded-lg text-[#0C4737] claimedButton'>
                Claimed 🎉
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const ExpiredCard = ({ huntInfo }) => {

  return (
    <>
      <div className='huntsCard w-full rounded-xl relative' data-reward={huntInfo.reward_type}>
        <div className='absolute w-full h-full bg-[#00000021] z-10 rounded-xl'></div>
        <div className='w-full h-[120px] max-h-[120px] overflow-hidden rounded-t-xl'>
          <img src={require('../assets/img/avatar.png')} alt='' className='object-cover grayscale h-full w-full' />
        </div>
        <div className='p-4'>
          <p className='title text-lg fontBold text-[#fa6002]'>{huntInfo.hunt_title}</p>
          <div className='infoWrapper h-[80px]'>
            <p className='text-sm huntInfo text-[#ebe6e6]'>{huntInfo.hunt_description}</p>
          </div>
          <p>{huntInfo.claimable} claimed</p>
          <div className='flex justify-between items-center mt-5'>
            <button className='bg-[#BCF0DA] px-4 py-1 rounded-lg text-[#0C4737] claimedButton'>
              Ended 🕓
            </button>

            <div className='flex items-center space-x-2'>
              {(() => {
                switch (huntInfo.reward_type) {
                  case "sol":
                    return (
                      <img src={require('../assets/img/sol.png')} className='h-[20px] w-[20px]' alt='' />
                    )
                  case "boop":
                    return (
                      <CiBitcoin className='text-[#fa6002]' size={25} />
                    )
                  case "bonk":
                    return (
                      <img src={require('../assets/img/bonk.png')} className='h-[25px] w-[30px]' alt='' />
                    )
                  case "chili":
                    return (
                      <img src={require('../assets/img/chili.png')} className='h-[25px] w-[30px]' alt='' />
                    )

                  default:
                    break;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


const PfpCard = ({ handleNavigate }) => {
  return (
    <>
      <div className='huntsCard min-w-[300px] w-32 cursor-pointer rounded-xl' onClick={handleNavigate}>
        <div className='w-full h-[120px] max-h-[150px] overflow-hidden rounded-t-xl'>
          <img src={require('../assets/img/avatar.png')} alt='' className='object-contain' />
        </div>
        <div className='p-4'>
          <p className='title text-lg fontBold text-[#fa6002]'>Flex Hasuki PFP</p>
          <div className='infoWrapper'>
            <p className='text-sm huntInfo text-[#ebe6e6]'>Use the hasuki NFT as your twitter pfp to earn reward daily.</p>
          </div>
          <div className='flex justify-between items-center mt-5'>
            <div className='font-bold flex items-center space-x-2'>
              <CiBitcoin className='text-[#fa6002]' size={25} />
              <p>10 $BOOP</p>
            </div>
            <div className='flex items-center space-x-2'></div>
          </div>

          <button className='w-full mt-5 textPrimary'>Coming soon !</button>
        </div>
      </div>
    </>
  )
}




export default Hunts