import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Header, Sidebar } from "../components";
import { MdOutlineLeaderboard } from "react-icons/md";
import { DarkModeProvider } from '../context/DarkModeContext'
import { useWallet } from '@solana/wallet-adapter-react';
import { HiOutlineTicket } from 'react-icons/hi'
import { HOST, configuration } from '../utils/constants';
import { Spinner, Tabs } from 'flowbite-react';
import { useSelector } from 'react-redux'
import { AiOutlineFileDone } from 'react-icons/ai'
import { GiJumpingDog, GiBirdTwitter } from 'react-icons/gi'
import TwitterFollowers from '../components/TwitterFollowers'


const Leaderboard = () => {
  const [usersData, setusersData] = useState(null)
  const [myRank, setmyRank] = useState(null)
  const [rankNum, setrankNum] = useState(0)
  const [twuserdata, settwusers] = useState(null)
  const [tid, setid] = useState(null)
  let { publicKey, connected } = useWallet();

  const { userInfo } = useSelector(
    (state) => state.user
  )


  useEffect(() => {
    (async () => {


      try {
        let response = await axios.get(`${HOST}/auth/getTopUsers/`, configuration)
        let allUsers = response.data.data

        setusersData(allUsers)
        // settwusers(TwitterFilter)
        // setid(TwitterFilter)

        if (userInfo) {
          setmyRank(allUsers.filter(a => a.address === userInfo.address))
          setrankNum(allUsers.findIndex(obj => obj.address === userInfo.address))

        }


      } catch (error) {
        console.log(error)
      }



    })()
  }, [connected, userInfo])

  return (
    <DarkModeProvider>
      <Header />
      <section className="h-screen flex relative dark:bg-[#181f31]">
        <Sidebar />
        <div className='h-full lg:pl-[320px] dark:bg-[#181f31] w-full mt-24 px-[3%] dark:text-gray-300'>
          <h1 className='text-xl fontBold mb-5 mt-6' style={{ color: '#fff' }}><MdOutlineLeaderboard style={{ display: 'inline-block' }} size={25} /> Hasuki Leaderboards</h1>


          <p className='fontBold mb-2'>My Rank</p>
          {myRank && (
            <LeaderboardCard dataToUse={myRank[0]} rank={rankNum + 1} />

          )}
          <hr className='mb-5' />
          {usersData ? (
            <>
              {usersData.map((dataToUse, i) => (
                <LeaderboardCard dataToUse={dataToUse} key={i} rank={i + 1} />
              ))}
            </>
          ) : (
            <div className='flex justify-center items-center'>
              <Spinner size="lg" />
            </div>
          )}



        </div>
      </section>
    </DarkModeProvider >
  )
}
// bg-[#2B3656]
const LeaderboardCard = ({ dataToUse, rank }) => (
  <div className='dark:bg-[#181f31] mb-5 p-3 rounded-md flex justify-between items-center'>
    <div className='flex space-x-2'>
      <div className='bg-[#4D5D75] flex justify-center items-center p-1 rounded-full'>
        <span className='sm:w-8 w-6 text-center'>{rank}</span>
      </div>
      <div className='border-r-2 border-[#4D5D75]'></div>
      <div className='flex items-center space-x-2 sm:pl-4 pl-1'>
        <img className='w-10 rounded-full' src={dataToUse?.profileImageUrl} alt="pfp" />
        <p className='text-sm sm:text-lg fontBold'>{dataToUse?.name}</p>
      </div>
    </div>

    <div className='flex items-center justify-end sm:text-center text-right space-x-2'>
      <HiOutlineTicket size={20} className='sm:block hidden' />
      <p className='text-sm sm:text-base fontBold'>{dataToUse?.XP} XP · LVL{dataToUse?.Level}</p>
    </div>
  </div>
)

export default Leaderboard