import React, { useState, useEffect } from 'react'
import { AmbassadorMetrics, ClaimAmbassador, ConnectWallet, Header, RequestCode, Sidebar } from "../components";
import { DarkModeProvider } from '../context/DarkModeContext'
import { toast, ToastContainer } from 'react-toastify';
import { SiSlideshare } from "react-icons/si"
import { useWallet } from '@solana/wallet-adapter-react';
import axios from "axios";
import { configuration, HOST } from '../utils/constants';
import { Spinner } from 'flowbite-react';


const Ambassador = () => {
  const [trigger, setTrigger] = useState(false)
  const { publicKey, connected, disconnect } = useWallet();
  const [ambassadorData, setAmbassadorData] = useState(null)


  useEffect(() => {
    setAmbassadorData(null)
    async function getAmbassador() {
      try {
        const response = await axios.get(`${HOST}/auth/getAmbassador?address=${publicKey.toBase58()}`, configuration)
        const ambasssador = response.data

        setAmbassadorData(ambasssador)

      } catch (error) {
        console.log(error)
        toast.error("Something went wrong !", {
          autoClose: 3000
        })
      }
    }

    if (publicKey) {
      getAmbassador()
    } else {

    }

  }, [trigger, connected])


  return (
    <DarkModeProvider>
      <Header />
      <ToastContainer />
      <section className="h-screen flex relative dark:bg-[#181F31]">
        <Sidebar />

        <section className='h-full lg:pl-[310px] w-full mt-24 px-[3%] dark:text-gray-300'>
          <p className='headings'><SiSlideshare style={{ display: 'inline-block' }} size={33} /> Ambassador</p>

          {publicKey ? (
            <>
              {ambassadorData ? (
                <>
                  {(ambassadorData?.status === 0) ? (
                    <RequestCode setTrigger={setTrigger} trigger={trigger} />

                  ) : (
                    <>
                      <AmbassadorMetrics data={ambassadorData && ambassadorData.data} />
                      <ClaimAmbassador data={ambassadorData && ambassadorData.data} />
                    </>
                  )}

                </>
              ) : (
                <div className='flex justify-center h-[70%] items-center'>
                  <Spinner size="lg" />
                </div>
              )}

            </>
          ) : (
            <>
              <ConnectWallet />
            </>
          )}




        </section>

      </section>
    </DarkModeProvider>
  )
}

export default Ambassador