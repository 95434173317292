import React, { useEffect, useState } from 'react'
import { GiWolfHowl, GiJumpingDog } from 'react-icons/gi'
import { GiSittingDog } from 'react-icons/gi'
import { MdOutlineLeaderboard } from "react-icons/md";
import { VscFlame } from "react-icons/vsc"
import { SiSlideshare } from "react-icons/si"
import { Progress } from 'flowbite-react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { BiWallet } from 'react-icons/bi'
import { FaDiscord } from 'react-icons/fa';
import { NavLink } from 'react-router-dom'
import { xpRequired } from '../utils/constants'

function Sidebar() {
  const [theRequired, setTheReuired] = useState(0)
  const [theProgress, setTheProgress] = useState(0)
  const navigate = useNavigate();
  const { userInfo } = useSelector(
    (state) => state.user
  )

  useEffect(() => {
    if (userInfo) {
      let theRequiredLVL = xpRequired[userInfo.Level - 1]
      setTheReuired(theRequiredLVL)
      setTheProgress((userInfo.XP / theRequiredLVL) * 100)

    }

  }, [userInfo])

  return (
    <div className='h-screen fixed shadow-2xl bg-[#0F172A] w-[280px] left-[-300px] lg:left-[0] top-[60px] pt-8 px-5 transition-all z-20' id='sidebar'>
      <div className='logoContainer sm:hidden flex items-center space-x-3 mb-3'>
        <img src={require('../assets/img/logo.png')} className='w-8 sm:w-12 rounded-full pjLogo' alt='' />
        <h3 className='font-bold dark:text-white text-[#010612] text-sm sm:text-lg'>HasukiRaid</h3>
      </div>
      {userInfo && (
        <div className='w-full p-3 text-[#fff] bg-[#4d5d75] rounded-md mb-5'>
          <div className="flex items-center space-x-2 cursor-pointer" onClick={() => navigate('/profile')}>
            <div>
              <img className='w-12 h-12 object-cover rounded-full shadow-4xl' src={userInfo && userInfo.profileImageUrl} alt='pfp' />
            </div>


            <div className="flex-1">
              <div>
                <h1 className='text-base mb-1 fontBold'>{userInfo && userInfo.name}</h1>
                <Progress
                  progress={theProgress}
                  size="sm"
                />
                <div className='flex justify-between mt-1'>
                  <p className='text-xs font-bold'>{userInfo && userInfo.XP}/{userInfo && theRequired} XP</p>
                  <p className='text-xs font-bold'>LVL {userInfo && userInfo.Level}</p>
                </div>
              </div>

            </div>


          </div>
        </div>
      )}
      <div className='headerItems flex flex-col gap-10'>
        <NavLink to="/hunts" className={({ isActive }) => (isActive ? 'active navoLink' : 'navoLink')}>
          <GiJumpingDog size={25} />
          <p className='font-bold pl-2 mb-0'>Hunts</p>
        </NavLink>

        <NavLink to="/mywallet" className={({ isActive }) => (isActive ? 'active navoLink' : 'navoLink')}>
          <BiWallet size={25} />
          <p className='font-bold pl-2 mb-0'>My Wallet</p>
        </NavLink>

        <NavLink to="/leaderboard" className={({ isActive }) => (isActive ? 'active navoLink' : 'navoLink')}>
          <MdOutlineLeaderboard size={25} />
          <p className='font-bold pl-2 mb-0'>Leaderboard</p>
        </NavLink>

        <NavLink to="/profile"
          className={({ isActive }) => (isActive ? 'active navoLink' : 'navoLink')}>
          <GiSittingDog size={22} />
          <p className='font-bold pl-2 mb-0'>Profile</p>
        </NavLink>

        <NavLink to="/ambassador" className={({ isActive }) => (isActive ? 'active navoLink' : 'navoLink')}>
          <SiSlideshare size={25} />
          <p className='font-bold pl-2 mb-0'>Ambassador</p>
        </NavLink>

        <NavLink to="/publish" className='button text-white flex items-center gap-2'>
          <VscFlame size="25" />
          <span className='font-bold'>Publish a Hunt</span>
        </NavLink>

        <a href="https://discord.gg/jADdp4AUQ7" rel="noreferrer" target="_blank" className="block md:hidden text-white font-bold">Join Discord</a>
        <a href="https://magiceden.io/marketplace/hasuki" rel="noreferrer" target="_blank" className="block md:hidden text-white font-bold">Marketplace</a>
      </div>

      <div className='absolute w-10/12'>
        <div className="mt-5 grid grid-cols-3 justify-items-center">
          <a className="socialIcCont" href="https://twitter.com/HasukiNFTs" rel="noreferrer" target='_blank' title='Hasuki Twitter'>
            <BsTwitter />
          </a>
          <a className="socialIcCont" href="https://discord.gg/aqtNQbHw5h" rel="noreferrer" target='_blank' title='Hasuki Discord'>
            <FaDiscord />
          </a>
          <a className="socialIcCont" href="https://www.instagram.com/hasukiofficial/" rel="noreferrer" target='_blank' title='Hasuki Instagram'>
            <BsInstagram />
          </a>
          {/* <a className="socialIcCont" href="#">
            <RiGlobalLine />
          </a> */}
        </div>
      </div>
    </div>
  )
}

export default Sidebar