import React, { useEffect, useState } from 'react'
import { appendScript } from '../utils/loadScript'
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { QuickNodeUrl, HOST, configuration } from '../utils/constants';

import { Spinner } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux'
import { registerWallet, fetchWallet } from '../redux/userActions'
import { shortenAddress } from '../utils/shortenAddress'
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { NavLink } from 'react-router-dom'
import { VscFlame } from "react-icons/vsc"

function Welcome() {
  const [IsNotHolder, setIsNotHolder] = useState(true)
  const [IsHolder, setIstHolder] = useState(true)
  const [loadingWall, setLoadingWal] = useState(false)

  const navigate = useNavigate();
  const { userInfo } = useSelector(
    (state) => state.user
  )
  const dispatch = useDispatch()

  let { publicKey, connected } = useWallet();

  useEffect(() => {
    setIsNotHolder(false)
    setLoadingWal(false)
    setIstHolder(false)

    if (publicKey) {
      setLoadingWal(true)
      axios.get(`${HOST}/auth/createUser?userIdentifier=${publicKey.toBase58()}`, configuration)
        .then(res => {

          if (res.data.status === 1) {
            setIstHolder(true)
            setLoadingWal(false)
            dispatch(fetchWallet(publicKey.toBase58()))
            navigate(`/hunts`)

          } else if (res.data.status === 2) {
            setIstHolder(true)
            setLoadingWal(false)

            dispatch(registerWallet(res.data.data))
            setTimeout(() => {
              navigate(`/hunts`)
            }, 1000);

          } else if (res.data.status === 3) {
            setLoadingWal(false)
            setIsNotHolder(true)

          } else {
            setLoadingWal(false)
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
              theme: "colored",
              autoClose: 4000
            });
          }

        }).catch(err => {
          setLoadingWal(false)
          toast.error("An error occured, Please try again !", {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            autoClose: 800
          });
        })
    }

  }, [connected])

  return (
    <section className='px-10 py-3 h-full w-full flex justify-center items-center'>
      <div className='flex justify-center self-center p-8 items-center text-center flex-col' style={{ borderRadius: '5px' }}>
        <img src={require("../assets/img/logo.png")} alt="" className="w-[100px] h-[100px] rounded-full" />
        <h1 className='fontBold text-xl mt-3 text-white'>Welcome to HASUKI RAID</h1>
        <p className='text-white uppercase'>Raid 2 Earn platform</p>

        <p className='mb-2 mt-10 text-white'>connect your wallet to start raiding</p>
        <div className='connectWalletBtn'>
          <WalletMultiButton />
        </div>

        {loadingWall && (
          <Spinner size="lg" className='mt-6' />
        )}
        <ToastContainer />
        {IsNotHolder && (
          <div className='mt-5 text-white'>
            <p>You need atleast "1" Hasuki NFTs in your wallet</p>
            <a target='_blank' rel="noreferrer" className='textPrimary fontBold' href='https://magiceden.io/marketplace/hasuki'>
              Go to Marketplace
            </a>
          </div>
        )}

        {IsHolder && (
          <div className='mt-5'>
            <p>You are a Hasuki holder</p>
            <div className='flex items-center space-x-2'>
              <Spinner />
              <span>Taking you In ...</span>
            </div>
          </div>
        )}

        <p className='Info'></p>

        <NavLink to="/publish" className='connectWalletBtn rounded-lg text-white mt-5 mb-4'>
          <div className='flex items-center gap-2 wallet-adapter-button'>
            <VscFlame size="25" />
            <span className=''>Publish a Hunt</span>
          </div>

        </NavLink>


        <div className='mt-1'>
          <p className='fontBold text-sm text-gray-400'>© 2022
            <a target='_blank' rel="noreferrer" className='textPrimary' href='https://twitter.com/HasukiLabs'> Hasuki Labs</a>, Inc. All right reserved.</p>
        </div>
      </div>
    </section>
  )
}

export default Welcome