import React, { useState, useEffect } from 'react'
import { HiOutlineTicket } from 'react-icons/hi'
import { CiBitcoin, CiMoneyBill } from "react-icons/ci";
import { Progress } from 'flowbite-react'
import axios from 'axios';
import { TOKEN_MINT, xpRequired, HOST, configuration } from '../utils/constants'

function LevelXp({ userInfo }) {
  const [tokenBalance, setTokenBalance] = useState(0)
  const [theRequired, setTheReuired] = useState(0)
  const [theProgress, setTheProgress] = useState(0)

  useEffect(() => {
    async function getTokenBalnce() {
      try {
        const response = await fetch(`${HOST}/auth/getTokenBalnce?address=${userInfo.address}`, configuration)
        const data = await response.json()

        setTokenBalance(data.balance)

      } catch (error) {
        console.log(error)
        setTokenBalance(0)
      }
    };

    if (userInfo) {
      let theRequiredLVL = xpRequired[userInfo.Level - 1]
      setTheReuired(theRequiredLVL)
      setTheProgress((userInfo.XP / theRequiredLVL) * 100)
      getTokenBalnce()
    }
  }, [userInfo])


  return (
    <section>
      <div className='md:flex-row flex-col flex space-x-0 space-y-4 md:space-y-0 md:space-x-4 items-center'>

        <div className='w-full h-36 p-5 bg-[#2B3656] rounded-md'>
          <h1 className='flex mb-10 items-center space-x-2 fontBold text-lg'>
            <HiOutlineTicket size={20} className='text-[#fa6002]' />
            <span>{userInfo && userInfo.XP} XP</span>
          </h1>
          <div className='flex justify-between mb-1'>
            <p className='text-sm font-bold'>{userInfo && userInfo.XP}/{userInfo && theRequired} XP</p>
            <p className='text-sm font-bold'>LVL {userInfo && userInfo.Level}</p>
          </div>
          <Progress
            progress={theProgress}
            size="sm"
          />
        </div>
        <div className='w-full h-36 p-5 bg-[#2B3656] rounded-md'>
          <p className='flex items-center gap-1 fontBold'>Your Earnings <CiMoneyBill className='textPrimary' size={20} /> </p>

          <div className='flex items-center gap-2 mt-3'>
            <img src={require("../assets/img/sol.png")} className="w-[20px] h-[20px]" alt="" />
            <p className='fontBold'>{userInfo && parseFloat(userInfo.solEarned).toFixed(3)} SOL</p>
          </div>

          <div className='flex items-center gap-2 mt-3'>
            <CiBitcoin size={22} className="textPrimary" />
            <p className='fontBold'>{userInfo && userInfo.boopEarned} BOOP</p>
          </div>

        </div>
        <div className='w-full h-36 p-5 bg-[#2B3656] rounded-md'>
          <div className='flex flex-col w-full items-center justify-center'>
            <CiBitcoin size={40} className='text-[#fa6002]' />
            <h1 className='mt-3 fontBold text-xl'>{parseFloat(tokenBalance).toFixed(2)} $BOOP</h1>
          </div>
        </div>

      </div>
    </section>
  )
}

export default LevelXp