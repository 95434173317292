import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { HiOutlineTicket } from 'react-icons/hi'
import { Button, FileInput, Progress, Spinner } from 'flowbite-react'
import { HOST, configuration } from '../utils/constants'
import { ConnectWallet, Header, Sidebar } from "../components";
import { DarkModeProvider } from '../context/DarkModeContext'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWallet } from '../redux/userActions'
import { CiBitcoin } from "react-icons/ci";
import { useNavigate } from 'react-router-dom'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

import { useWallet } from '@solana/wallet-adapter-react';
import { BsInfoSquare } from 'react-icons/bs'

import PublitioAPI from 'publitio_js_sdk'
import { shortenAddress } from '../utils/shortenAddress';

const Hunt = () => {
  const { huntId } = useParams()
  const navigate = useNavigate();
  const [HuntData, setHuntData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [claiming, setClaiming] = useState(false)
  const [claimed, setClamed] = useState(false)
  const [LoadingSt, setLoadingSt] = useState(true)
  const [clmed, setclmed] = useState(true)

  const [selectedImage, setSelectedImage] = useState(null);   //NEW - initiate the variable and function to handle file selection
  const { publicKey, connected } = useWallet();               //variable to check connection

  const { userInfo } = useSelector(
    (state) => state.user
  )
  const dispatch = useDispatch()

  useEffect(() => {
    async function getNFTS() {
      try {
        const responsee = await axios.get(`${HOST}/auth/checkUserNFT?userIdentifier=${publicKey.toBase58()}`, configuration)
        const NFTS = responsee.data

        if (NFTS.status === 1) {

        } else {
          navigate('/')
        }

      } catch (error) {
        console.log(error)
      }
    }
    if (publicKey) {
      getNFTS()
    }

  }, [connected])


  useEffect(() => {
    setLoading(false)
    setClaiming(false)
    setClamed(false)
    setHuntData(null)
    setLoadingSt(true)

    async function getHunt() {
      try {
        const response = await axios.get(`${HOST}/auth/retriveHunt?id=${huntId}`, configuration)


        const hunt_data = response.data

        const response2 = await axios.get(`${HOST}/auth/retriveClaimed?tweetID=${hunt_data.data.tweet_id}&address=${userInfo.address}`, configuration)

        const hunt_info = response2.data

        setHuntData(hunt_data.data)

        // let theHuntInfo = hunt_info.data.filter(ff => ff.tweet_id === hunt_data.data.tweet_id)
        // setHuntInfo(hunt_info)
        if (hunt_info.isClaimed) {
          setClamed(true)
          setLoadingSt(false)
        } else {
          setClamed(false)
          setLoadingSt(false)
        }

      } catch (error) {
        console.log(error)
        setLoadingSt(false)
      }
    }

    if (userInfo) {
      getHunt()
    }

  }, [userInfo, clmed])

  const handleImageChange = (e) => {    //event handler for HTML <input type="file">
    const file = e.target.files;
    setSelectedImage(file);

    console.log(file)
  };

  const fileUpload = async () => {
    if (userInfo?.twitterName === "") {

      toast.info("Please go to profile and link your twitter Username", {   //MESSAGE: Inform the user that they need to select an image.
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
        autoClose: 3000
      });

    } else {
      try {
        if (!selectedImage) {
          toast.info("Please select a picture to upload", {   //MESSAGE: Inform the user that they need to select an image.
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            autoClose: 1000
          });
          return;
        }


        setClaiming(true)

        const publitio = new PublitioAPI(process.env.REACT_APP_PUBLITIO_KEY, process.env.REACT_APP_PUBLITIO_KEY2)

        let count = 0
        try {
          const responses = await Promise.all(
            Array.from(selectedImage).map(async (file) => {
              const response = await publitio.uploadFile(file, 'file', {
                folder: "RaidsEvidence" + HuntData?.tweet_id,
                tag: "raid_" + userInfo?.address,
                title: shortenAddress(userInfo?.address) + HuntData?.tweet_id + count++,
                description: "Raid evidence for user " + userInfo?.address
              });
              return response;
            })
          );

          // console.log('Upload responses:', responses);
          let dataToUpdate = {
            user_identifier: userInfo.address,
            picture: [],
            huntID: huntId,
            userXP: userInfo.XP,
            tweet_id: HuntData.tweet_id,
            payAmount: HuntData.token_reward,
            twiterusername: userInfo.twitterName,
            tweet_url: HuntData.tweet_url
          }
          responses.forEach(resss => dataToUpdate['picture'].push(resss.url_preview))
          // console.log(dataToUpdate)
          async function uploadHunt() {
            try {
              const response = await axios.post(`${HOST}/auth/upload/uploadHunts`, dataToUpdate, configuration)
              // console.log(response.data)
              toast.success("Uploaded Successfully !", {
                position: toast.POSITION.TOP_CENTER,
                theme: "dark",
                autoClose: 1000
              });

              setClaiming(false)
              setclmed(prevState => !prevState)

              setTimeout(() => {
                navigate('/hunts')
              }, 1000);

            } catch (error) {
              console.log(error)
              toast.warning("Error uploading hunt!", {
                position: toast.POSITION.TOP_CENTER,
                theme: "dark",
                autoClose: 1000
              });
              setClaiming(false)
            }
          }
          uploadHunt()
        } catch (error) {
          console.error('Error uploading files:', error);
          setClaiming(false)
        }


      } catch (error) { // errorsHandler 
        setClaiming(false)
        console.log(error)
        toast.error("An Error Occured !", {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          autoClose: 1000
        });
      }
    }
  };

  function clearSelection() {
    setSelectedImage(null)

    const newFileInput = document.querySelector('.huntImage input');
    newFileInput.value = ""
  }


  return (
    <DarkModeProvider>
      <Header />
      <ToastContainer />
      <section className="h-screen flex relative dark:bg-[#181F31]">
        <Sidebar />
        <div className='h-full lg:pl-[320px] w-full mt-24 px-[5%] dark:text-gray-300'>
          <Button size="xs" outline={true} className="actBtn goBack mb-2" onClick={() => navigate(-1)}>
            Go Back
          </Button>

          {connected ? (
            <>
              {HuntData && (// HUNT PAGE - HEADER
                <>
                  <h1 className='text-2xl fontBold mb-2'>{HuntData.hunt_title}</h1>
                  <p className='mb-5 w-full sm:w-[60%]'>{HuntData.hunt_description}</p>

                  <div className='flex-col space-y-4 flex md:flex-row md:space-x-4 md:space-y-0'>
                    <div className='shadow-2xl border border-[#fa610248] rounded-md p-3 w-full'>
                      {HuntData.isExpired === true ? (  // HUNT PAGE - EXPIRED HUNT
                        <>
                          <div >
                            <div className='flex justify-center items-center mt-4'>
                              <img src={require('../assets/img/insurance.png')} className='w-20' alt='' />
                            </div>
                            <p className='text-lg text-center fontBold mt-5'>
                              All rewards claimed.
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          {claimed ? (                  //HUNT PAGE - ALREADY CLAIMED THE HUNT
                            <div >
                              <div className='flex justify-center items-center mt-4'>
                                <img src={require('../assets/img/insurance.png')} className='w-20' alt='' />
                              </div>
                              <p className='text-lg text-center fontBold mt-5'>
                                Your account has already claimed this
                              </p>
                            </div>
                          ) : (

                            /* HUNT PAGE - ACTIONMAP - STEPS TO COMPLETE THE HUNT */
                            <>
                              <div className='activitiesCenter mt-2'>
                                <p className='mt-2 mb-5 text-sm'>Complete these steps to claim your reward.</p>
                              </div>

                              {HuntData.actions.map((action, ii) => {
                                return (
                                  <ActivityCard
                                    key={ii}
                                    actionData={action}
                                    HuntData={HuntData}
                                    setLoading={setLoading}
                                    loading={loading}
                                    userInfo={userInfo}
                                  />
                                )
                              })
                              }

                              <hr className='border-[#fff4] mt-10' />
                              {publicKey ? (
                                <div className='mt-3'>
                                  <FileInput type="file" multiple className='huntImage' accept="image/*" onChange={handleImageChange} />

                                  {selectedImage && (
                                    <>
                                      <div className='flex justify-end'>
                                        <button onClick={clearSelection} className='text-sm textPrimary fontBold my-3'>clear selection</button>
                                      </div>

                                      {Array.from(selectedImage).map((selImg, i) => (
                                        <img key={i} src={URL.createObjectURL(selImg)} alt="Selected" />
                                      ))}

                                    </>
                                  )}



                                  {claiming ? (
                                    <div className='flex justify-center mt-3'>
                                      <Spinner size='xl' />
                                    </div>
                                  ) : (
                                    <button size="sm" className="button w-full mt-5" onClick={fileUpload}>
                                      Upload Image
                                    </button>
                                  )}

                                </div>

                              ) : (
                                <div className='connectWalletBtn huntSection w-full mt-5'>
                                  <WalletMultiButton />
                                </div>
                              )
                              }
                            </>
                          )}
                        </>
                      )
                      }
                    </div>

                    {/* //HUNT-PAGE - REWARDS SECTION */}
                    <div className='shadow-2xl border border-[#fa610248] h-fit rounded-md p-3 w-full md:w-9/12'>
                      <h3>REWARDS</h3>
                      <div className='flex items-center space-x-3 mt-2'>
                        <HiOutlineTicket className='text-[#fa6002] text-lg' />
                        <p className='font-bold'>{HuntData.xp_reward} XP</p>
                      </div>

                      {(HuntData.reward_type === "sol") ? (
                        <div className='flex items-center space-x-3 mt-2'>
                          <img src={require('../assets/img/sol.png')} className='w-[20px]' alt='' />
                          <p className='font-bold'>{HuntData.token_reward} SOL</p>
                        </div>

                      ) : (
                        <div className='flex items-center space-x-3 mt-2'>
                          <CiBitcoin className='text-[#fa6002] text-lg' size={22} />
                          <p className='font-bold'>{HuntData.token_reward} ${HuntData.reward_type.toUpperCase()}</p>
                        </div>
                      )}

                      <div className='flex gap-2 mt-8'>
                        <BsInfoSquare size={20} className='text-blue-500' />
                        <p className='text-xs fontBold'>The claimed reward might take a minute before reflecting in your wallet.</p>
                      </div>
                      <div className='flex gap-2 mt-3'>
                        <BsInfoSquare className=' text-blue-500' />
                        <p className='text-xs fontBold'>The system checks only your last 10 retweets and likes.</p>
                      </div>
                      <Button className='w-full fontBold mt-5' color='gray'>{HuntData.total_claimed} / {HuntData.claimable} Claimed</Button>
                    </div>
                  </div>
                </>            //HUNT-PAGE - END
              )}
            </>
          ) : (
            <>
              <ConnectWallet />
            </>
          )}

          {LoadingSt && (
            <div className='flex justify-center items-center h-[40vh]'>
              <Spinner size="xl" />
            </div>
          )}


        </div>
      </section>
    </DarkModeProvider>
  )

}


const ActivityCard = ({ actionData }) => {
  const StartAction = (actionInfo) => {
    window.open(actionInfo.tweet_url, "_blank")
  }


  return (
    <div className='activity px-4 py-3 bg-[#0a0f1f2a] rounded-md mb-3 flex justify-between items-center'>
      <p className='font-bold capitalize text-sm'>{actionData.description}</p>
      <div className='flex justify-center items-center space-x-2'>
        <Button size="sm" className='actBtn capitalize' onClick={() => StartAction(actionData)}>
          {actionData.action}
        </Button>
      </div>
    </div>
  )
}

export default Hunt
