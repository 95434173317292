import React, { useState, useEffect, useCallback } from 'react'

import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { HOST, configuration, QuickNodeUrl } from '../utils/constants'
import { GiWolfHowl, GiJumpingDog } from 'react-icons/gi'
import { useSelector } from 'react-redux'
import { Select, Spinner, Tabs, TextInput, Button, Checkbox, Label, Modal } from 'flowbite-react';
import { Header, Sidebar } from "../components";
import { DarkModeProvider } from '../context/DarkModeContext'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection, PublicKey, Transaction, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js'
import { getOrCreateAssociatedTokenAccount, createTransferInstruction } from "@solana/spl-token"
import world from '../assets/img/world.webp';
import roadmap from '../assets/img/roadmap.png';


const Hasuki = () => {
  let navigate = useNavigate();
  const { userInfo } = useSelector(
    (state) => state.user
  )
  const [HuntsData, setHuntsData] = useState(null)
  const [HuntInfo, setHuntInfo] = useState(null)
  const [ExpiredData, setExpiredData] = useState(null)


  let { connected } = useWallet();

  useEffect(() => {
   
   
  }, [connected])

   return (
    <DarkModeProvider>
      <Header />
      <ToastContainer />
      <section className="h-screen flex relative dark:bg-[#181F31]">
        <Sidebar />

        <section className='h-full lg:pl-[320px] w-full mt-24 px-[5%] dark:text-gray-300'>
          <div className='flex sm:flex-row flex-col mt-4 sm:items-center justify-center'>
          
            <p className='headings mb-10 mt-10' style={{ color: '#4d5d75' }}><GiWolfHowl style={{display:'inline-block'}} size={33} /> The World of Hasuki Welcomes You</p>

          </div>

          <div className='grid grid-cols-1 justify-center'>
            <img src={world} />
          </div>
          <div className='grid grid-cols-1 justify-center'>
            <h2 className='headings mt-8 mb-2'>The Formation - The Beginning</h2>

            <p className='mb-8 bodytext'>The Hasuki community is forming. Embracing the strength of both individuality and inclusivity. Celebrating individual differences, discovering commonalities, and coming together in unity. The Pack assembles.</p>  
            <h2 className='headings mt-8 mb-4 '>Assembly - Pack Formation</h2>

            <p className='mb-2 bodytext'>The Pack has assembled and continues to get stronger. Being a member of the pack is highly coveted. Benefits of membership transcend into both virtual and real-life collaborations, merchandise, social events, commercial rights, and more..</p>
            
            <h2 className='headings mt-8 mb-4 '>Expansion - The Pack Evolves</h2>

            <p className='mb-2 bodytext'>The Hasukis final form has yet to be revealed. The only thing that is certain is no one knows what will happen next.</p>

          </div>
<hr className='mt-6 mb-4' style={{border: "1px solid"}} />
          <div className='grid grid-cols-1 justify-center items-center text-center mt-10'>
            <img src={roadmap} />
          </div>

          <div className='grid grid-cols-1 justify-center'>
           
            <h2 className='headings mt-8 mb-2'>PHASE I</h2>
            <ul className='pl-4'>
              <li>* Holders Verification #MatricaLabs</li>
              <li>* Hasuki Map: Virtual community map </li>
              <li>* Staking: Token earnings to be used within the ecosystem, partnership with staking capital</li>
              <li>* Token Creation $BOOP </li>
              <li>* NFTInspect - Verified Collection, used to reward most loyal @trainers</li>
              <li>* 1/1 HonoraryNFTs</li>
              <li>* HASUKI-FI: Art-based partnership with our favourite communities, @NekozumaNFT</li>
            </ul>

             
            <h2 className='headings mt-8 mb-2'>PHASE 2</h2>
            <ul className='pl-4'>
              <li>* Free Mint NFT collab drop</li>
              <li>* WL Offerings and Raffles for holders</li>
              <li>* Creation of Beta Tester Peer Group</li>
              <li>* Hasuki Hunts: Social engagement/Raid2Earn Platform launch</li>
              <li>* Provide Hasuki Hunts as a white-label service for other NFT projects</li>
              <li>* Ambassador Program for Hasuki Trainers</li>
              <li>* Surprise airdrop</li>
              <li>* Hasuki Store: Exclusive merch, physical collectibles, children's books, toys and more (available in $SOL and $BOOP)</li>
              <li>* Art upgrades: upgrade your Hasuki using $BOOP</li>
            </ul>


            <h2 className='headings mt-8 mb-2'>PHASE 3</h2>
            <ul className='pl-4'>
                <li>* Further development by Hasuki Labs and Hasuki Studio</li>
                <li>* Provide liquidity for $BOOP thru strategic DeFi funding </li>
            </ul>

          


          </div>
         

          
        </section>
      </section>
    </DarkModeProvider >
  )
}

const onSubmit = data => {
  
  // data.clientID = clientData.twitterId
  console.log(data);
  
};



export default Hasuki