import React, { useState, useEffect } from 'react'
import { SiSlideshare } from "react-icons/si"
import { useWallet } from '@solana/wallet-adapter-react';
import axios from "axios";
import { HOST, configuration } from "../utils/constants";
import { toast, ToastContainer } from 'react-toastify';
import { Spinner } from 'flowbite-react'

const RequestCode = ({ setTrigger, trigger }) => {
  let { publicKey, connected, disconnect } = useWallet();
  const [loading, setloading] = useState(false)

  useEffect(() => {
    setloading(false)
  }, [])

  async function requestCode() {

    setTrigger(prevState => !prevState)

    if (publicKey) {
      setloading(true)
      try {
        let dataToAdd = {
          "address": publicKey.toBase58()
        }
        const response = await axios.post(`${HOST}/auth/createAmbassador`, dataToAdd, configuration)
        const ambasssador = response.data

        if (ambasssador.status === 2) {
          toast.success("Ambassador Code generated 🎉", {
            autoClose: 3000
          })
          setTrigger(prevState => !prevState)
          setloading(false)
        } else {
          setTrigger(prevState => !prevState)
        }

      } catch (err) {
        console.log(err, ' error creating Ambassador')
        toast.error("Something went wrong !", {
          autoClose: 3000
        })
        setloading(false)
      }
    } else {
      alert('please connect your wallet !')
    }

  }

  return (
    <div className='flex justify-center items-center h-[70%]'>
      <ToastContainer />
      <div>
        <div className='flex justify-center'>
          <SiSlideshare size={30} />
        </div>
        <p className='text-2xl fontBold'>Request for your code</p>

        <div className='flex justify-center mt-4'>
          {loading ? (
            <Spinner />
          ) : (
            <button className='button' onClick={requestCode}>Request</button>
          )}

        </div>
      </div>
    </div>
  )
}

export default RequestCode