import React, { useState, useEffect } from 'react'
import { Header, Sidebar, ConnectWallet, LevelXp, Hasukis, EditModal, LinkTwitter } from "../components";
import axios from "axios";
import { DarkModeProvider } from '../context/DarkModeContext'
import { shortenAddress } from '../utils/shortenAddress'
import { useWallet } from '@solana/wallet-adapter-react';
import { useDispatch, useSelector } from 'react-redux'
import { HOST, configuration } from '../utils/constants'
import { FiEdit2 } from 'react-icons/fi'
import { GiRank2 } from "react-icons/gi";
import { BsTwitter } from 'react-icons/bs'
import { FaDiscord } from 'react-icons/fa'
import { IoWalletOutline } from 'react-icons/io5'
import { fetchWallet } from '../redux/userActions'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RewardHistory from '../components/RewardHistory';

const Profile = () => {
  // const { urlWalletAddress } = useParams()
  const [editModal, setEditModal] = useState(false)
  let { publicKey, connected } = useWallet();
  const [TwitterModal, setTwitterModal] = useState(false)
  const [PhotoUpdating, setPhotoUpdating] = useState(false)
  const dispatch = useDispatch()
  const [rankNum, setrankNum] = useState(0)

  const { userInfo } = useSelector(
    (state) => state.user
  )

  useEffect(() => {
    (async () => {
      try {
        let response = await axios.get(`${HOST}/auth/getAllUsers/`, configuration)
        let allUsers = response.data.data

        let sortedUsers = allUsers.sort((a, b) => (a.XP < b.XP ? 1 : -1));
        setrankNum(sortedUsers.findIndex(obj => obj.name === userInfo.name))

      } catch (error) {
        console.log(error)
      }
    })()
  }, [connected])

  async function AuthenticateTwitter() {
    setTwitterModal(true)
    // const response = await axios.get(`${HOST}/auth/sendAddress?address=${userInfo.address}`, configuration)
    // window.open(`${HOST}/auth/twitter`, "_self");
  }
  function copyToClip() {
    navigator.clipboard.writeText(userInfo.address);
    toast.info("copied", {
      position: toast.POSITION.TOP_CENTER,
      theme: "dark",
      autoClose: 800
    });
  }

  const removeTwitter = () => {

    (async function () {
      let dataToUpdate = {
        address: userInfo.address
      }
      try {
        const response = await axios.put(`${HOST}/auth/unLinkTwitter`, dataToUpdate, configuration)
        dispatch(fetchWallet(userInfo.address))
        toast.info("Unlinked successfully", {
          autoClose: 400
        })
      } catch (error) {
        console.log(error)
      }

    }())
  }
  return (
    <DarkModeProvider>
      <Header />
      <ToastContainer />
      <section className="h-screen flex relative">
        <Sidebar />
        <div className='h-full lg:pl-[320px] w-full mt-24 px-[3%] dark:text-gray-300'>
          <h1 className='text-xl fontBold mb-5'>My Profile</h1>
          {connected ? (
            <>
              <div className='profSection flex items-center gap-4'>
                <div className='avatarCont'>
                  <img className='w-20 h-20 object-cover rounded-full shadow-4xl' src={userInfo && userInfo.profileImageUrl} alt='pfp' />
                </div>
                <div className='infoProf w-full'>
                  <h1 className='text-lg fontBold'>{userInfo && userInfo.name}</h1>

                  <div className='md:flex-row flex flex-col items-center md:space-y-0 space-y-2 md:space-x-2 mt-3'>
                    <button onClick={() => setEditModal(true)} className='editProf flex justify-center w-full items-center gap-2 border border-[#fa6002] px-14 md:px-20 rounded-lg py-1'>
                      <FiEdit2 />
                      <span className='font-bold'>Edit Profile</span>
                    </button>
                    <button className='editProf flex justify-center w-full items-center gap-2 border border-[#fa6002] px-14 md:px-20 rounded-lg py-1'>
                      <GiRank2 size={20} />
                      <span className='font-bold'>#{rankNum + 1} Rank</span>
                    </button>
                  </div>
                  <p className='mt-3'>{userInfo && userInfo.description}</p>
                </div>
              </div>

              <div className='profSection mt-4'>
                <LevelXp userInfo={userInfo} />
              </div>

              <div className='profSection mt-4 h-[300px] overflow-y-auto'>
                <h1 className='fontBold text-lg'>Recents Raids</h1>

                <RewardHistory />


              </div>
              <div className='profSection mt-4 h-[300px] overflow-y-auto'>
                <h1 className='fontBold text-lg'>My Hasukis</h1>

                <Hasukis />


              </div>



              <div className='profSection mt-4'>
                <h1 className='fontBold text-lg'>Accounts</h1>

                <div className='mt-4'>
                  <div className='flex items-center space-x-2 w-full'>
                    <div className='bg-[#2b3656] flex flex-1 items-center space-x-2 p-3 rounded-md'>
                      <BsTwitter size={30} className='pr-2 border-r-2 border-[#fa610289]' />
                      {userInfo && userInfo.twitterId !== "" ? (
                        <p className='font-bold'>{userInfo.twitterName}</p>
                      ) : (
                        <p>Connect your twitter</p>
                      )}

                    </div>
                    {userInfo && userInfo.twitterId ? (
                      <button className='button unlink' onClick={removeTwitter}>Remove</button>
                    ) : (
                      <button className='button unlink' onClick={AuthenticateTwitter}>Connect</button>
                    )}

                  </div>

                  {/* <div className='flex items-center mt-5 space-x-2 w-full'>
                    <div className='bg-[#2b3656] flex flex-1 items-center space-x-2 p-3 rounded-md'>
                      <FaDiscord size={30} className='pr-2 border-r-2 border-[#fa610289]' />
                      <p>Broski.dev#7711</p>
                    </div>
                    <button className='button unlink'>Remove</button>
                  </div> */}

                  <div className='flex items-center mt-5 space-x-2 w-full'>
                    <div className='bg-[#2b3656] flex flex-1 items-center space-x-2 p-3 rounded-md'>
                      <IoWalletOutline size={30} className='pr-2 border-r-2 border-[#fa610289]' />
                      <p>{userInfo && shortenAddress(userInfo.address)}</p>
                    </div>
                    <button className='button unlink' onClick={copyToClip}>Copy</button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <ConnectWallet />
          )}


        </div>
      </section>

      <EditModal
        userInfo={userInfo}
        editModal={editModal}
        setEditModal={setEditModal}
        PhotoUpdating={PhotoUpdating}
        setPhotoUpdating={setPhotoUpdating}
      />
      <LinkTwitter
        setTwitterModal={setTwitterModal}
        TwitterModal={TwitterModal}
        userInfo={userInfo}
      />
    </DarkModeProvider>
  )
}

export default Profile