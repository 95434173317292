import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";
import { HOST, configuration } from "../utils/constants";
// userAction.js
export const registerWallet = createAsyncThunk(
  // action type string
  'user/register',
  // callback function
  async (userData, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      // make request to backend
      // console.log(userData)
      return userData
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }

  }
)

export const fetchWallet = createAsyncThunk(
  'user/getUserDetails',
  async (userAddress, { rejectWithValue }) => {
    try {

      const { data } = await axios.get(`${HOST}/auth/findUser?address=${userAddress}`, configuration)

      return data.data
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  }
)