import React, { useState, useEffect } from 'react'
import PublishHeader from '../components/PublishHeader'
import { NavLink, useNavigate } from 'react-router-dom'
import { HOST, configuration } from '../utils/constants'
import { useWallet } from '@solana/wallet-adapter-react';
import axios from 'axios'
import { Spinner, Modal, Tabs } from 'flowbite-react';
import { VscFlame } from 'react-icons/vsc';
import { ConnectWallet, LoginTwitter } from "../components";
import { useSelector } from 'react-redux';
import { GiJumpingDog, GiBirdTwitter } from 'react-icons/gi'
import { TfiWrite } from 'react-icons/tfi'

const https = require('https');

const Publish = () => {
  const navigate = useNavigate();
  let { publicKey, connected, disconnect } = useWallet();
  const [clientData, setClientData] = useState(null)
  const [clientHunts, setClientHunts] = useState(null)
  const [TwitterModal, setTwitterModal] = useState(false)
  // retriveClientHunts

  const { userInfo } = useSelector(
    (state) => state.user
  )

  useEffect(() => {
    async function checkingUser() {
      let key = publicKey.toBase58()
      let dataToSend = {
        clientID: key
      }
      console.log(key)
      try {


        const response = await axios.get(`${HOST}/auth/checkClient?clientId=${publicKey.toBase58()}`, configuration)
        // const response = await axios.post(`${HOST}/auth/chkClient`, dataToSend, configuration)

        const userData = response.data

        if (userData.status === 1) {
          setClientData(userData.data)

          fetcHunts(publicKey.toBase58())

        } else {
          setClientData(userData.data)
          fetcHunts(publicKey.toBase58())
        }

      } catch (err) {
        console.log(err, ' error finding user in DB')
      }
    }


    if (publicKey) {
      checkingUser()
    }

  }, [connected])

  async function fetcHunts(clientID) {
    try {
      const response2 = await axios.get(`${HOST}/auth/retrieveClientHunts?clientID=${clientID}`, configuration)
      const huntData = response2.data

      if (huntData.length === 0) {
        setClientHunts([])
      } else {
        setClientHunts(huntData.data)
      }

    } catch (err) {
      console.log(err, ' error finding user in DB')
    }
  }

  return (
    <div className='px-[5%]'>

      <PublishHeader clientData={clientData} />

      {publicKey ? (
        <>
          <h1 className='text-2xl text-white mt-5 fontBold'>Hello, {clientData && clientData.username} 👋</h1>


          {clientHunts ? (
            <>

              {clientHunts.length === 0 ? (
                <section className='px-10 py-3 h-full w-full flex flex-col justify-center items-center'>
                  {/* <div className='flex h-full justify-center self-center p-8 items-center text-center flex-col'>
                    <p className='text-white mb-3 font-bold'>Create Tweet Hunts on Hasuki Platform and watch your Twitter flourish.</p>

                    <Tabs.Group aria-label="Tabs with icons" className='mt-5' style="underline" id="tabsss">
                      <Tabs.Item active={true} title={`Create Hunts`} icon={GiJumpingDog}>
                        <PublishModal />
                      </Tabs.Item>
                      <Tabs.Item active={true} title={`Create Tweet Raid`} icon={GiJumpingDog}>
                        <TweetEarn></TweetEarn>
                      </Tabs.Item>
                    </Tabs.Group>



                  </div> */}
                  <p className='text-white mb-5 mt-20'>Create Tweet Hunts on Hasuki Platform and watch your Twitter flourish.</p>
                  <div>
                    <NavLink to="/createhunt" className='connectWalletBtn rounded-lg text-white mt-5'>
                      <div className='flex items-center gap-2 wallet-adapter-button mb-5'>
                        <VscFlame size="25" />
                        <span className=''>Raid 2 Earn</span>
                      </div>

                    </NavLink>

                    <NavLink to="/tweet2earn" className='connectWalletBtn rounded-lg text-white mt-5'>
                      <div className='flex items-center gap-2 wallet-adapter-button'>
                        <TfiWrite size="25" />
                        <span className=''>Tweet 2 Earn</span>
                      </div>

                    </NavLink>
                  </div>

                </section>
              ) : (
                <>
                  <div className='flex flex-wrap justify-end gap-3'>
                    <NavLink to="/createhunt" className='connectWalletBtn rounded-lg text-white mt-5'>
                      <div className='flex items-center gap-2 wallet-adapter-button mb-5'>
                        <VscFlame size="25" />
                        <span className=''>Raid 2 Earn</span>
                      </div>

                    </NavLink>

                    <NavLink to="/tweet2earn" className='connectWalletBtn rounded-lg text-white mt-5'>
                      <div className='flex items-center gap-2 wallet-adapter-button'>
                        <TfiWrite size="25" />
                        <span className=''>Tweet 2 Earn</span>
                      </div>

                    </NavLink>
                  </div>

                  <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-10">
                    {clientHunts.reverse().map((huntInfo, i) => (
                      <HuntsCard huntInfo={huntInfo} key={i} />
                    ))}
                  </section>
                </>
              )}

            </>
          ) :
            (
              <div className='justify-center flex'>
                <Spinner size="lg" />
              </div>
            )
          }


        </>

      ) : (
        <>
          <ConnectWallet />
        </>
      )}

    </div >
  )
}

const HuntsCard = ({ huntInfo }) => {
  let isCompleted = false

  // if (userInfo && claimsInfo) {
  //   let claimed = claimsInfo[0].claimers.filter(claimerss => claimerss === userInfo.address)
  //   let claimed2 = claimsInfo[0].twitterclaimers.filter(claimerss => claimerss === userInfo.twitterId)
  //   if (claimed.length === 0 && claimed2.length === 0) {
  //     isCompleted = false
  //   } else {
  //     isCompleted = true
  //   }
  // }

  return (
    <>
      <div className='huntsCard w-full cursor-pointer rounded-xl' data-reward={huntInfo.reward_type}>
        <div className='w-full h-[120px] max-h-[120px] overflow-hidden rounded-t-xl'>
          <img src={huntInfo.hunt_image} alt='' className='object-cover h-full w-full' />
        </div>
        <div className='p-4'>
          <p className='title text-lg fontBold text-[#fa6002]'>{huntInfo.hunt_title}</p>
          <div className='infoWrapper h-[80px]'>
            <p className='text-sm huntInfo text-[#ebe6e6]'>{huntInfo.hunt_description}</p>
          </div>

          <div className='flex justify-between'>
            <div>
              <p className='fontBold text-white'>Claims</p>
              <p className='fontBold text-sm textPrimary'>{huntInfo.claimable} Raids</p>
            </div>

          </div>

          <div className='flex justify-between items-center mt-5'>

            {(huntInfo.isApproved === false) && (
              <button className='bg-[#a74923] px-4 py-1 rounded-lg text-[white] fontBold claimedButton'>
                pending approval ⏰
              </button>
            )}

            {(huntInfo.isApproved === true && huntInfo.isExpired === false) && (
              <button className='bg-[green] px-4 py-1 rounded-lg text-[white] fontBold claimedButton'>
                Live 🕗
              </button>
            )}

            {(huntInfo.isExpired === true) && (
              <button className='bg-[#657e65] px-4 py-1 rounded-lg text-[white] fontBold claimedButton'>
                Completed ✅
              </button>
            )}

          </div>
        </div>
      </div>

    </>
  )
}

export default Publish