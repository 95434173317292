import React, { useState, useEffect } from 'react'
import { Modal, TextInput, Label, Textarea } from 'flowbite-react'
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux'
import axios from "axios";
import { HOST, configuration } from "../utils/constants";
import { Spinner } from 'flowbite-react'
import { fetchWallet } from '../redux/userActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LinkTwitter({ TwitterModal, setTwitterModal, userInfo }) {
  const { register, handleSubmit } = useForm();
  const [lodingIt, setLoadingIt] = useState(false)

  const dispatch = useDispatch()
  useEffect(() => {
    setLoadingIt(false)
  }, [])

  function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const timestamp = new Date().getTime().toString();
    const randomChars = [];

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomChars.push(characters.charAt(randomIndex));
    }

    // Combine random characters, numbers, and timestamp
    const randomString = randomChars.join('') + timestamp;

    return randomString;
  }

  const handleEdit = (Formdata) => {
    if (Formdata.username === "") {
      setTwitterModal(false)
    } else {
      setLoadingIt(true)

      async function getTwee() {
        let dataToUpdate = {
          address: userInfo.address,
          updateData: {
            twitterName: Formdata.username,
            twitterId: generateRandomString(10)
          }
        }
        try {
          const response = await axios.get(`${HOST}/auth/findUserTweet?theName=${Formdata.username}`, configuration)
          const theRes = response.data

          if (theRes.isExist) {
            toast.info("This account is already linked with another account !", {
              autoClose: 2000,
            })
            setLoadingIt(false)
          } else {
            updateUser(dataToUpdate)
          }

        } catch (error) {
          toast.info("Username not found", {
            autoClose: 2000,
          })
          console.log(error)
        }
      }
      getTwee()
    }

    async function updateUser(dataToUpdate) {
      console.log(dataToUpdate)
      try {
        const response = await axios.put(`${HOST}/auth/updateUserTwitter`, dataToUpdate, configuration)
        console.log(dataToUpdate)
        dispatch(fetchWallet(userInfo.address))
        setTwitterModal(false)
        setLoadingIt(false)

        toast.success("Account Linked successfully", {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
          autoClose: 1000
        });

      } catch (error) {
        console.log(error)
        setLoadingIt(false)
      }

    }

  }

  return (
    <Modal
      show={TwitterModal}
      size="xl"
      popup={true}
      onClose={() => setTwitterModal(false)}>
      <ToastContainer />
      <Modal.Header>
        <span className='m-3'>Link Twitter</span>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6 pb-4 sm:pb-6 xl:pb-8 editForm">
          <form onSubmit={handleSubmit(handleEdit)} className='mt-10'>
            <div className='mb-3'>
              <Label htmlFor='wallet' value='Twitter Username' />
              <TextInput
                id="wallet"
                defaultValue=''
                {...register('username')}
                className='mt-1'
                placeholder='E.g BroskiXii dont include @'
              />
            </div>

            {lodingIt ? (
              <div className='flex justify-center'>
                <Spinner />
              </div>
            ) : (
              <button className='button text-white mt-5 w-full'>Link Twitter</button>
            )}

          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default LinkTwitter