import React, { useState } from "react";
import { Header, Hunts, Welcome, Sidebar } from "./components";
import { DarkModeProvider } from './context/DarkModeContext'

function Main() {
  const [connected, setConnected] = useState(false)

  return (
    <div className="h-screen dark:bg-[#010612] dark:text-white overflow-y-auto">
      {/* <DarkModeProvider> */}
        {(connected && <Header />)}
        <section className="h-full flex relative">
          {(connected && <Sidebar />)}

          {(connected ? (
            <div className='h-full lg:pl-[260px] bg-[#010612]'>
              <Hunts />
            </div>
          ) : (
            <Welcome />
          ))}

        </section>
      {/* </DarkModeProvider> */}
    </div>
  )
}

export default Main