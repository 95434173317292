import React, { useEffect, useState } from 'react'
import { useWallet } from '@solana/wallet-adapter-react';
import axios from 'axios'
import { QuickNodeUrl } from '../utils/constants'

const Hasukis = () => {
  let { connected, publicKey } = useWallet();
  const [Hasukis, setHasukis] = useState(null)
  const [loadingHasukis, setLoadingHasukis] = useState(false)

  useEffect(() => {
    if (!publicKey) {
      setHasukis(null)
    } else {
      // setLoadingHasukis(true)
      (async () => {
        let page = 1
        setLoadingHasukis(true)
        while (page !== false) {
          const data = {
            jsonrpc: "2.0",
            id: 1,
            method: "qn_fetchNFTs",
            params: {
              wallet: publicKey.toBase58(),
              omitFields: ["provenance", "traits"],
              page: page,
              perPage: 100,
            },
          };
          try {
            const responsee = await axios.post(QuickNodeUrl, data)
            const NFTS = responsee.data.result.assets

            if (NFTS.length) {
              let hasukiNFTs = NFTS.filter(nft => nft.collectionName === "Hasuki")

              if (hasukiNFTs.length === 0) {
                page++
              } else {
                page = false
                let hasukiNFTs = NFTS.filter(nft => nft.collectionName === "Hasuki")
                setHasukis(hasukiNFTs)
                setLoadingHasukis(false)
              }
            } else {
              page = false
              setLoadingHasukis(false)
              setHasukis(null)
            }


          } catch (error) {
            console.log(error)
            page = false
            setHasukis(null)
          }

        }
      })()
    }
  }, [connected])

  return (
    <>

      {Hasukis === null ? (
        <div className=''>
          {loadingHasukis ? (
            <div className="flex justify-center items-center py-3">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#A851D8]" />
            </div>
          ) : (
            <p className='text-lg text-center font-bold mt-5'>You are not a Holder.</p>
          )}
        </div>
      ) : (
        <div className='grid grid-cols-4 gap-3 mt-5'>

          {Hasukis.map((hasuki, i) => {
            let magicURL = `https://magiceden.io/item-details/${hasuki.tokenAddress}`
            return (
              <a href={magicURL} key={i} rel="noreferrer" className='transition-all hover:scale-105' target='_blank'>
                <img className="rounded-lg w-36" src={hasuki.imageUrl} alt="Hasuki" />
                <p className="text-left fontBold text-base text-[#c7c7c7] p-1">Hasuki {hasuki.name}</p>
              </a>
            )
          })}
        </div>
      )}

    </>
  )
}

export default Hasukis