import React from 'react'

const ErrorPage = () => {
  return (
    <div>
      ERROR ! PAGE NOT FOUND
    </div>
  )
}

export default ErrorPage