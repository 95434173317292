import React from 'react'
import { AiOutlineLock, AiOutlinePercentage } from 'react-icons/ai';
import { CiShoppingTag, CiMoneyBill } from 'react-icons/ci';
import { BsCartCheck } from 'react-icons/bs';

const AmbassadorMetrics = ({ data }) => {
  return (
    <>
      <div className="grid grid-cols-4 gap-4 mt-5">

        <div className='ambCards'>
          <div className='flex justify-between'>
            <p className='firstP'>My Code</p>
            <CiShoppingTag />
          </div>
          <p className='secondP'>{data?.code}</p>
        </div>

        <div className='ambCards'>
          <div className='flex justify-between'>
            <p className='firstP'>Share</p>
            <AiOutlinePercentage />
          </div>
          <p className='secondP'>{data?.percentagePerOrder} %</p>
        </div>

        <div className='ambCards'>
          <div className='flex justify-between'>
            <p className='firstP'>Total Orders</p>
            <BsCartCheck />
          </div>
          <p className='secondP'>{data?.Orders}</p>
        </div>

        <div className='ambCards'>
          <div className='flex items-start justify-between'>
            <p className='firstP'>Total Value</p>
            <CiMoneyBill />
          </div>

          <div className='flex justify-between'>
            <div>
              <p className='fontBold'>BOOP</p>
              <p className='textPrimary fontBold'>{data?.totalBoopValue}</p>
            </div>
            <div>
              <p className='fontBold'>SOL</p>
              <p className='textPrimary fontBold'>{data?.totalSolValue}</p>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default AmbassadorMetrics