import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { HiOutlineTicket } from 'react-icons/hi'
import { Button, Progress, Spinner } from 'flowbite-react'
import { HOST, configuration } from '../utils/constants'
import { ConnectWallet, Header, Sidebar } from "../components";
import { DarkModeProvider } from '../context/DarkModeContext'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWallet } from '../redux/userActions'
import { CiBitcoin } from "react-icons/ci";
import { useNavigate } from 'react-router-dom'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

import { useWallet } from '@solana/wallet-adapter-react';
import { BsInfoSquare } from 'react-icons/bs'

const TweetHunt = () => {
  const { huntId } = useParams()
  const navigate = useNavigate();
  const [TweetHuntData, setHuntData] = useState(null)
  const [TweetHuntInfo, setHuntInfo] = useState(null)
  const [stepsCom, setStepsCom] = useState(0)
  const [loading, setLoading] = useState(false)
  const [claiming, setClaiming] = useState(false)
  const [claimed, setClamed] = useState(false)
  const [twClaimed, setTwtClaimed] = useState(false)
  const [LoadingSt, setLoadingSt] = useState(true)
  // staging
  const { userInfo } = useSelector(
    (state) => state.user
  )
  const dispatch = useDispatch()

  useEffect(() => {
    setLoading(false)
    setClaiming(false)
    setClamed(false)
    setTwtClaimed(false)
    setHuntData(null)
    setLoadingSt(true)

    async function getTweetHunt() {
      try {
        const response = await axios.get(`${HOST}/auth/retriveTweetHunts?id=${huntId}`, configuration)
        const response2 = await axios.get(`${HOST}/auth/retrieveTweetHuntInfo`, configuration)
        const hunt_data = response.data
        const hunt_info = response2.data
        console.log(hunt_data)
        setHuntData(hunt_data.data)

        let theHuntInfo = hunt_info.data.filter(ff => ff.tweet_id === hunt_data.data.tweet_id)
        setHuntInfo(theHuntInfo[0])

        const claimedOrNot = theHuntInfo[0].claimers.filter(claimer => claimer === userInfo.address)
        const twitterClaimed = theHuntInfo[0].twitterclaimers.filter(twtclaimer => twtclaimer === userInfo.twitterId)

        if (claimedOrNot.length === 0 && twitterClaimed.length === 0) {
          setClamed(false)
          setLoadingSt(false)
        } else {
          setClamed(true)
          setLoadingSt(false)
        }

      } catch (error) {
        console.log(error)
        setLoadingSt(false)
      }
    }

    if (userInfo) {
      getTweetHunt()
    }

  }, [userInfo])

  const { publicKey, connected } = useWallet();
  console.log(TweetHuntData)

  async function claimReward() {
    try {
      if (stepsCom !== TweetHuntData.actions.length) {
        toast.info("Tweet about te mentioned content to claim your reward", {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          autoClose: 1000
        });
      } else {
        setClaiming(true)
        let info_needed = {
          ...userInfo,
          ...TweetHuntData
        }
        const response = await axios.post(`${HOST}/auth/claimReward`, info_needed, configuration)
        const responsedata = await response.data

        if (responsedata.isPaid) {
          toast.success("Reward claimed successfully 🎉 !", {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored"
          });
          dispatch(fetchWallet(userInfo.address))
          setClaiming(false)
          setTimeout(() => {
            navigate("/hunts")
          }, 1000);
        } else {
          toast.info(responsedata.message, {
            position: toast.POSITION.TOP_CENTER,
            theme: "colored",
            autoClose: 1000
          });
          setClaiming(false)
        }

      }
    } catch (error) {
      setClaiming(false)

      toast.error("An Error Occured !", {
        position: toast.POSITION.TOP_CENTER,
        theme: "colored",
        autoClose: 1000
      });
    }

  }

  return (
    <DarkModeProvider>
      <Header />
      <ToastContainer />
      <section className="h-screen flex relative dark:bg-[#181f31]">
        <Sidebar />
        <div className='h-full lg:pl-[320px] w-full mt-24 px-[5%] dark:text-gray-300'>
          <Button size="xs" outline={true} className="actBtn goBack mb-2" onClick={() => navigate(-1)}>
            Go Back
          </Button>

          {connected ? (
            <>
              {TweetHuntData && (
                <>
                  <h1 className='text-2xl fontBold mb-2'>{TweetHuntData.hunt_title}</h1>
                  <p className='mb-5 w-full sm:w-[60%]'>{TweetHuntData.hunt_description}</p>
                  <div className='flex-col space-y-4 flex md:flex-row md:space-x-4 md:space-y-0'>

                    <div className='shadow-2xl border border-[#fa610248] rounded-md p-3 w-full'>

                      {claimed ? (
                        <div >
                          <div className='flex justify-center items-center mt-4'>
                            <img src={require('../assets/img/insurance.png')} className='w-20' alt='' />
                          </div>
                          <p className='text-lg text-center fontBold mt-5'>
                            Your account has already claimed this
                          </p>
                        </div>
                      ) : (
                        <>
                          <div className='activitiesCenter mt-2'>
                            <p className='mt-2 mb-5 text-sm'>Tweet to claim your reward.</p>
                            {TweetHuntData.actions.map((action, i) => {
                              return (
                                <TwitterHundCard
                                  key={i}
                                  actionData={action}
                                  HuntData={TweetHuntData}
                                  setLoading={setLoading}
                                  loading={loading}
                                  userInfo={userInfo}
                                />
                              )
                            })}




                            <hr className='border-[#fff4] mt-10' />
                            {publicKey ? (
                              <ClaimButton claiming={claiming} claimReward={claimReward} />
                            ) : (
                              <div className='connectWalletBtn huntSection w-full mt-5'>
                                <WalletMultiButton />
                              </div>
                            )}
                          </div>
                        </>
                      )}


                    </div>

                    <div className='shadow-2xl border border-[#fa610248] h-fit rounded-md p-3 w-full md:w-9/12'>
                      <h3>REWARDS</h3>
                      <div className='flex items-center space-x-3 mt-2'>
                        <HiOutlineTicket className='text-[#fa6002] text-lg' />
                        <p className='font-bold'>{TweetHuntData.xp_reward} XP</p>
                      </div>
                      {(() => {
                        switch (TweetHuntData.reward_type) {
                          case "sol":
                            return (
                              <div className='flex items-center space-x-3 mt-2'>
                                <img src={require('../assets/img/sol.png')} className='w-[20px]' alt='' />
                                <p className='font-bold'>{TweetHuntData.token_reward} SOL</p>
                              </div>
                            )
                          case "boop":
                            return (
                              <div className='flex items-center space-x-3 mt-2'>
                                <CiBitcoin className='text-[#fa6002] text-lg' size={22} />
                                <p className='font-bold'>{TweetHuntData.token_reward} $BOOP</p>
                              </div>
                            )
                          case "bonk":
                            return (
                              <div className='flex items-center space-x-3 mt-2'>
                                <img src={require('../assets/img/bonk.png')} className='w-[30px]' alt='' />
                                <p className='font-bold'>{TweetHuntData.token_reward} $BONK</p>
                              </div>
                            )

                          case "chili":
                            return (
                              <div className='flex items-center space-x-3 mt-2'>
                                <img src={require('../assets/img/chili.png')} className='w-[30px]' alt='' />
                                <p className='font-bold'>{TweetHuntData.token_reward} $CHILI</p>
                              </div>
                            )

                          case "rizz":
                            return (
                              <div className='flex items-center space-x-3 mt-2'>
                                <img src="https://media.publit.io/file/rizz.jpeg" className='w-[30px]' alt='' />
                                <p className='font-bold'>{TweetHuntData.token_reward} $RIZZ</p>
                              </div>
                            )

                          default:
                            break;
                        }
                      })()}


                      <div className='flex gap-2 mt-8'>
                        <BsInfoSquare size={20} className='text-blue-500' />
                        <p className='text-xs fontBold'>The claimed reward might take a minute before reflecting in your wallet.</p>
                      </div>
                      <div className='flex gap-2 mt-3'>
                        <BsInfoSquare className=' text-blue-500' />
                        <p className='text-xs fontBold'>The system checks only your last 10 retweets and likes.</p>
                      </div>
                      <Button className='w-full fontBold mt-5' color='gray'>{TweetHuntInfo && TweetHuntInfo.twitterclaimers.length} / {TweetHuntData.claimable} Claimed</Button>
                    </div>

                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <ConnectWallet />
            </>
          )}

          {LoadingSt && (
            <div className='flex justify-center items-center h-[40vh]'>
              <Spinner size="xl" />
            </div>
          )}


        </div >
      </section >
    </DarkModeProvider >
  )
}

const ClaimButton = ({ claimReward, claiming }) => {
  switch (claiming) {
    case true:
      return (
        <div>
          <div className='flex justify-center mt-5'>
            <Spinner
              color="pink"
              size="lg"
              aria-label="Pink spinner example"
            />
          </div>
          <p className='mb-4 text-lg text-center fontBold'>Claiming .....</p>
        </div>
      )
    case false:
      return (
        <button className='button w-full mt-5' onClick={claimReward}>Claim Reward</button>

      )
  }
}

const TwitterHundCard = ({ actionData, setLoading, TweetHuntData, userInfo }) => {
  const StartAction = (actionInfo) => {
    window.open(actionInfo.tweet_url, "_blank")
  }
  const comfirmTask = async (e) => {
    if (userInfo.twitterId === "") {
      toast.info("Please go to profile and link your twitter account", {
        position: toast.POSITION.TOP_CENTER,
        theme: "dark",
        autoClose: 1000,
      });
    } else {
      try {
        let activitiesCenter = document.querySelector(".activitiesCenter")
        activitiesCenter.style.pointerEvents = "none"

        e.target.innerHTML = `
            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-[#fa6002]" />
            `
        e.target.parentElement.style.pointerEvents = "none"
        let taskInfo = {
          userid: userInfo.address,
          userTid: userInfo.twitterId,
          ...TweetHuntData,
          actionDone: actionData.action
        }

        const response = await axios.post(`${HOST}/auth/verifyTask`, taskInfo, configuration)
        const data = await response.data

        if (data.isDone) {
          successfully()
        } else {
          failed(data.message)
        }

      } catch (error) {
        console.log(error)
        failed("An Error Occured !")
      }

      function failed(msg) {
        e.target.innerHTML = "Confirm"
        e.target.parentElement.style.pointerEvents = "auto"
        toast.info(msg, {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          autoClose: 1000
        });
        let activitiesCenter = document.querySelector(".activitiesCenter")
        activitiesCenter.style.pointerEvents = "auto"
      }
      function successfully() {
        e.target.innerHTML = "Done"
        e.target.parentElement.style.pointerEvents = "none"
        setLoading(false)
        let activitiesCenter = document.querySelector(".activitiesCenter")
        activitiesCenter.style.pointerEvents = "auto"
      }
    }


  }


}

const ActivityCard = ({ actionData, setLoading, TweetHuntData, userInfo, setStepsCom, stepsCom }) => {
  const StartAction = (actionInfo) => {
    window.open(actionInfo.tweet_url, "_blank")
  }

  const comfirmTask = async (e) => {
    if (userInfo.twitterId === "") {
      toast.info("Please go to profile and link your twitter account", {
        position: toast.POSITION.TOP_CENTER,
        theme: "dark",
        autoClose: 1000,
      });
    } else {
      try {
        let activitiesCenter = document.querySelector(".activitiesCenter")
        activitiesCenter.style.pointerEvents = "none"

        e.target.innerHTML = `
        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-[#fa6002]" />
        `
        e.target.parentElement.style.pointerEvents = "none"
        let taskInfo = {
          userid: userInfo.address,
          userTid: userInfo.twitterId,
          ...TweetHuntData,
          actionDone: actionData.action
        }

        const response = await axios.post(`${HOST}/auth/verifyTask`, taskInfo, configuration)
        const data = await response.data

        if (data.isDone) {
          successfully()
        } else {
          failed(data.message)
        }

      } catch (error) {
        console.log(error)
        failed("An Error Occured !")
      }

      function failed(msg) {
        e.target.innerHTML = "Confirm"
        e.target.parentElement.style.pointerEvents = "auto"
        toast.info(msg, {
          position: toast.POSITION.TOP_CENTER,
          theme: "colored",
          autoClose: 1000
        });
        let activitiesCenter = document.querySelector(".activitiesCenter")
        activitiesCenter.style.pointerEvents = "auto"
      }
      function successfully() {
        e.target.innerHTML = "Done"
        e.target.parentElement.style.pointerEvents = "none"
        setStepsCom(stepsCom + 1)
        setLoading(false)
        let activitiesCenter = document.querySelector(".activitiesCenter")
        activitiesCenter.style.pointerEvents = "auto"
      }
    }


  }

  return (
    <div className='activity px-4 py-3 bg-[#0a0f1f2a] rounded-md mb-3 flex justify-between items-center'>
      <p className='font-bold capitalize text-sm'>{actionData.description}</p>
      <div className='flex justify-center items-center space-x-2'>
        <Button size="sm" className='actBtn capitalize' onClick={() => StartAction(actionData)}>
          {actionData.action}
        </Button>
        <Button size='sm' onClick={comfirmTask}>
          Confirm
        </Button>
      </div>
    </div>
  )
}

export default TweetHunt