import React, { useState, useEffect } from 'react'
import axios from "axios";
import { HOST, configuration } from "../utils/constants";
import logo from '../assets/img/logo.png'
import { HiOutlineMenuAlt1 } from 'react-icons/hi'
import { useWallet } from '@solana/wallet-adapter-react';
import { shortenAddress } from '../utils/shortenAddress'
import ThemeSwitcher from './ThemeSwitcher'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useDispatch, useSelector } from 'react-redux'
import { fetchWallet } from '../redux/userActions'
import { useNavigate } from 'react-router-dom'

import BoopHeader from './BoopHeader';

const Header = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector(
    (state) => state.user
  )
  const dispatch = useDispatch()
  let { publicKey, connected, disconnect } = useWallet();

  useEffect(() => {

    if (publicKey) {
      // check for user existence in the DB
      async function checkingUser() {
        try {
          const response = await axios.get(`${HOST}/auth/findUser?address=${publicKey.toBase58()}`, configuration)
          const userData = response.data

          if (userData.isUser === false) {
            navigate('/')
          } else {
            // Do nothing brother
            dispatch(fetchWallet(publicKey.toBase58()))



          }
        } catch (err) {
          console.log(err, ' error finding user in DB')
        }

      }
      checkingUser()

    }

  }, [connected, disconnect])


  function openSideBar() {
    let sideBar = document.querySelector('#sidebar')
    if (sideBar.style.left === '0px') {
      sideBar.style.left = '-300px'
    } else {
      sideBar.style.left = '0px'
    }

  }

  return (
    <>
      <div className='px-[5%] py-3 border-b bg-white dark:bg-[#0F172A] border-[#ffffff] header fixed w-full z-30'>
        <div className='flex justify-between gap-6 items-center'>
          <div className='flex items-center space-x-1'>
            <div className='lg:hidden block'>

              <HiOutlineMenuAlt1 className='cursor-pointer text-white' onClick={openSideBar} size={25} />
            </div>
            <div className='sm:block hidden'>
              <div className='logoContainer flex flex-col justify-center items-center space-x-3'>
                <a className='flex flex-1 items-center justify-center' href='/' title='Hasuki Raid 2 Earn Tool'>
                  <img src={logo} className='w-8 sm:w-12 rounded-full pjLogo' alt='' />
                  <h3 className='font-bold pl-3 dark:text-white text-sm sm:text-lg'>HasukiRaid</h3>
                </a>
              </div>
            </div>
          </div>

          <div className='headerActions flex space-x-4 items-center'>
            <a href="https://discord.gg/jADdp4AUQ7" target="_blank" className="hidden md:block font-bold" >Join Discord</a>
            <a href='https://jup.ag/swap/SOL-BOOP' target='_blank' rel="noreferrer" className='font-bold hidden md:block'>Buy $BOOP</a>
            <a href="https://magiceden.io/marketplace/hasuki" target="_blank" className="hidden md:block font-bold" >Marketplace</a>
            <BoopHeader userInfo={userInfo} />
            {/* <ThemeSwitcher /> */}
            <div className='connectWalletBtn'>
              <WalletMultiButton />
            </div>
          </div>


        </div>
      </div>
    </>
  )
}

export default Header