import React, { useEffect, useState } from 'react'
import { useWallet } from '@solana/wallet-adapter-react';
import axios from 'axios'
import { QuickNodeUrl, HOST, configuration } from '../utils/constants'
import { Badge, Button, Spinner, Table } from 'flowbite-react';

// import jsonData from '../assets/txn.json';

// const TxnData = () => JSON.parse(jsonData);


// console.log(jsonData)

// let filteredData = TxnData.filter((jsonData) => jsonData.signer = "AdoJnKVzVvkFhY71Z9KMrpdsC3Ethh6TNQAPs7dqQgV1");         

const RewardHistory = () => {
  let { connected, publicKey } = useWallet();
  const [myraids, setmyraids] = useState(null)

  useEffect(() => {
    async function getRaidss() {
      try {
        const response = await axios.get(`${HOST}/auth/verifier/getUserRaids?usrId=${publicKey?.toBase58()}`, configuration)
        const dataRes = await response.data

        setmyraids(dataRes.data)

      } catch (error) {
        console.log(error)
        setmyraids([])
      }
    }

    getRaidss()

  }, [])



  return (
    <>

      {myraids ? (
        <>
          {myraids.length === 0 ? (
            <div className='flex justify-center'>
              <p className='text-lg'> No recents Raid</p>
            </div>
          ) : (
            <>

              <Table className='mt-4'>
                <Table.Head>
                  {["S/N", "Tweet Url", "Status", "Payout", "comment", "Date Submited"].map((thed, ii) => (
                    <Table.HeadCell key={ii}>{thed}</Table.HeadCell>
                  ))}
                </Table.Head>

                <Table.Body>
                  {myraids.reverse().map((mraid, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{i + 1}</Table.Cell>
                      <Table.Cell>
                        <Button onClick={() => window.open(mraid.tweet_url, "_blank")} size='xs'>open</Button>
                      </Table.Cell>

                      <Table.Cell>
                        {(() => {
                          switch (mraid.status) {
                            case "pending":
                              return (
                                <Badge color='warning' className='w-fit'>pending</Badge>
                              )
                            case "verified":
                              return (
                                <Badge color='success' className='w-fit'>approved</Badge>
                              )
                            case "rejected":
                              return (
                                <Badge color='failure' className='w-fit'>rejected</Badge>
                              )

                            default:
                              break;
                          }
                        })()}

                      </Table.Cell>

                      <Table.Cell>{mraid.payAmount}</Table.Cell>

                      <Table.Cell></Table.Cell>

                      <Table.Cell>{mraid.timestamp}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>

            </>
          )}
        </>
      ) : (
        <div className="flex justify-center">
          <Spinner size="lg" />
        </div>
      )
      }

    </>
  )
}

export default RewardHistory