import React from "react";
import Main from './Main'
import { Profile, ErrorPage, Hunt, Leaderboard, Hasuki, Hunts, Publish, CreateHunt, TweetEarn, TweetHunt, Ambassador, MyWallet } from './pages'
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ReactGA from 'react-ga';
import { useMemo } from "react";
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { toast, ToastContainer } from 'react-toastify';

import {
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  LedgerWalletAdapter,
  SolflareWalletAdapter,
  SolletWalletAdapter,
  SolletExtensionWalletAdapter,
  TorusWalletAdapter
} from '@solana/wallet-adapter-wallets';
import {
  WalletModalProvider,
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

function App() {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Mainnet;
  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const TRACKING_ID = "G-S64QVQC0W5"; // YOUR_OWN_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    [network]
  );


  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/hunts" element={<Hunts />} />
              <Route path="/hunt/:huntId" element={<Hunt />} />
              <Route path="/tweethunt/:huntId" element={<TweetHunt />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/leaderboard" element={<Leaderboard />} />
              <Route path="/publish" element={<Publish />} />
              <Route path="/hasuki" element={<Hasuki />} />
              <Route path="/createhunt" element={<CreateHunt />} />
              <Route path="/tweet2earn" element={<TweetEarn />} />
              <Route path="/ambassador" element={<Ambassador />} />
              <Route path="/mywallet" element={<MyWallet />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>

            <ToastContainer />
          </Router>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>

  );
}

export default App;
