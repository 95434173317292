import React, { useState, useEffect } from 'react'
import { Modal, TextInput, Label, Textarea, Spinner } from 'flowbite-react'
import { useForm } from "react-hook-form";
import { FiEdit2 } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import axios from "axios";
import { HOST, configuration } from "../utils/constants";
import { fetchWallet } from '../redux/userActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { busboy } from 'connect-busboy';
import PublitioAPI from 'publitio_js_sdk'

function EditModal({ editModal, setEditModal, userInfo, PhotoUpdating, setPhotoUpdating }) {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch()
  const [loding, setLoding] = useState(false)

  useEffect(() => {
    setLoding(false)
    setPhotoUpdating(false)
  }, [])

  const handleEdit = (data) => {
    setLoding(true)
    if (data.name === "" && data.description === "") {
      setEditModal(false)
      setLoding(false)
    } else {
      if (data.name === "") {
        data.name = userInfo.name
      }
      let dataToUpdate = {
        address: userInfo.address,
        ...data
      }
      async function updateUser() {
        try {
          const response = await axios.put(`${HOST}/auth/updateProfile`, dataToUpdate, configuration)
          const resData = response.data
          if (resData.status === 1) {
            toast.success("Profile Updated Successfully !!", {
              position: toast.POSITION.TOP_CENTER,
              theme: "colored",
              autoClose: 1000
            });
            dispatch(fetchWallet(userInfo.address))
            setEditModal(false)
            setLoding(false)
          } else {
            toast.info("Username taken already!", {
              position: toast.POSITION.TOP_CENTER,
              theme: "colored",
              autoClose: 1000
            });
            setLoding(false)
          }
        } catch (error) {
          console.log(error)
          setLoding(false)
        }

      }
      if (data.name === userInfo.name && data.description === userInfo.description) {
        setEditModal(false)
      } else {
        updateUser()
      }

    }
  };
  function updatePicture() {
    let thePicture = document.querySelector(".pictureInput")
    thePicture.click()
  }

  function uploadPicture() {
    setPhotoUpdating(true)
    let thePicture = document.querySelector(".pictureInput")
    // let theInput = thePicture.querySelector("input")
    const fileList = thePicture.files[0];
    const reader = new FileReader()
    reader.readAsBinaryString(fileList);

    // Create a new FormData object
    // const formData = new FormData();
    // Add the image file to the FormData object
    // formData.append('image', inputElement.files[0]);

    const publitio = new PublitioAPI(process.env.REACT_APP_PUBLITIO_KEY, process.env.REACT_APP_PUBLITIO_KEY2)
    publitio.uploadFile(fileList, 'file')
      .then((data) => {
        let dataToUpdate = {
          user_identifier: userInfo.address,
          profilePic: data.url_preview
        }
        async function updateUser() {
          try {
            const response = await axios.put(`${HOST}/auth/updateProfPicture`, dataToUpdate, configuration)
            // console.log(response.data)
            toast.success("Profile picture updated Successfully !", {
              position: toast.POSITION.TOP_CENTER,
              theme: "dark",
              autoClose: 1000
            });
            dispatch(fetchWallet(userInfo.address))
            setEditModal(false)
            setPhotoUpdating(false)
          } catch (error) {
            console.log(error)
            toast.warning("Error updating Profile picture !", {
              position: toast.POSITION.TOP_CENTER,
              theme: "dark",
              autoClose: 1000
            });
            setPhotoUpdating(false)
          }
        }
        updateUser()
      })
      .catch((error) => {
        console.log(error)
        toast.warning("Error updating Profile picture !", {
          position: toast.POSITION.TOP_CENTER,
          theme: "dark",
          autoClose: 1000
        });
        setPhotoUpdating(false)
      })


    // New image Uploader which uploads to the Hosting server
    /*let dataToUpdate = {
          user_identifier: userInfo.address,
          profilePic: formData
        }
    async function updateUser() {
          try {
            // const response = await axios.put(`${HOST}/auth/updateProfPicture`, dataToUpdate, configuration)
            fetch('${HOST}/auth/updateProfPicture', {
              method: 'POST',
              body: dataToUpdate
            })
            toast.success("Profile picture updated Successfully !", {
              position: toast.POSITION.TOP_CENTER,
              theme: "dark",
              autoClose: 1000
            });
            dispatch(fetchWallet(userInfo.address))
            setEditModal(false)
            setPhotoUpdating(false)
            .then(response => {
              console.log(response);
              // Handle response from server
            })
            .catch(error => {
              // Handle error
              console.error(error);
              toast.warning("Error updating Profile picture !", {
                position: toast.POSITION.TOP_CENTER,
                theme: "dark",
                autoClose: 1000
              });
              setPhotoUpdating(false)
            });
          }
            catch (error) {
                      console.log(error)
                      toast.warning("Error updating Profile picture !", {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "dark",
                        autoClose: 1000
                      });
                      setPhotoUpdating(false)
                    }
                }
          updateUser()
          */
  }

  return (
    <Modal
      show={editModal}
      size="xl"
      popup={true}
      onClose={() => setEditModal(false)}>
      <Modal.Header>

      </Modal.Header>
      <Modal.Body>
        <h1 className='-mt-6 mb-4 text-white text-lg fontBold'>Edit Your Profile</h1>
        <ToastContainer />
        <div className="space-y-6 pb-4 sm:pb-6 xl:pb-8 editForm">
          <div className='relative w-fit'>
            <div className='avatarCont bg-[#2b3656] shadow-2xl p-2 w-fit'>
              <img className='w-20' src={userInfo && userInfo.profileImageUrl} alt="avatar" />
            </div>
            <div className='bg-[#FA6002] absolute right-[-20px] bottom-[0] w-fit rounded-full p-2 cursor-pointer'>
              <FiEdit2 className='text-white' onClick={updatePicture} />
            </div>
          </div>
          {PhotoUpdating && (
            <div className='flex justify-center flex-col'>
              <div className='flex justify-center'>
                <Spinner size="lg" />
              </div>
              <p className='text-white text-center'>Updating profile picture</p>
            </div>
          )}
          <input type="file" className='hidden pictureInput' onChange={uploadPicture} />

          <form onSubmit={handleSubmit(handleEdit)}>
            <div className='mb-3'>
              <Label htmlFor='wallet' value='Wallet Address' />
              <TextInput
                id="wallet"
                defaultValue={userInfo && userInfo.address}
                readOnly={true}
                disabled={true}
                className='mt-1'
              />
            </div>

            <div className='mb-3'>
              <Label htmlFor='username' value='Username' />
              <TextInput
                id="username"
                defaultValue={userInfo && userInfo.name}
                className='mt-1'
                name="name"
                {...register('name')}
              />
            </div>

            <div className='mb-3'>
              <Label htmlFor='bio' value='Bio' />
              <Textarea
                id="username"
                defaultValue={userInfo && userInfo.description}
                className='mt-1'
                name="description"
                {...register('description')}
              />
            </div>
            {loding ? (
              <div className='flex justify-center'>
                <Spinner />
              </div>
            ) : (
              <>
                <button className='button text-white w-full'>Save</button>
              </>
            )}
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default EditModal