import React, { useState } from 'react'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { HOST, configuration } from '../utils/constants'
import { BsTwitter } from 'react-icons/bs';
import { useWallet } from '@solana/wallet-adapter-react';
import { NavLink } from 'react-router-dom';

const PublishHeader = ({ clientData }) => {

  const [isClientt, setisClientt] = useState(false)

  function signWithTwitter() {
    window.open(`${HOST}/auth/twitter`, "_self");
  }

  return (
    <div>
      <header className='text-white mt-4 flex flex-wrap justify-between pb-2'>
        <a href='/'>
          <div className='flex items-center gap-3'>
            <img src={require("../assets/img/logo.png")} className="w-[40px] h-[40px] rounded-full" alt="" />
            <p className='fontBold'>HasukiRaid</p>
          </div>
        </a>

        <div className='flex sm:gap-4 gap-2 items-center'>
          <NavLink to="/" className='text-white'>
            <button className='font-bold textPrimary'>Home</button>
          </NavLink>
          {/* <NavLink to="/publish" className='text-white'>
            <button className='font-bold textPrimary'>Dashboard</button>
          </NavLink> */}

          <a className='font-bold textPrimary' target='_blank' rel="noreferrer" href='https://jup.ag/swap/SOL-BOOP'>Buy $BOOP</a>

          <div className='connectWalletBtn'>
            <WalletMultiButton />
          </div>


        </div>




      </header>
    </div>
  )
}

export default PublishHeader