import React, { useState, useEffect } from 'react'
import { CiBitcoin } from "react-icons/ci";
import axios from 'axios';
import { TOKEN_MINT, HOST, configuration } from '../utils/constants'
import { useWallet } from '@solana/wallet-adapter-react';

function BoopHeader() {
  const [Balnce, setBalnce] = useState(0)
  const { publicKey, connected } = useWallet();

  useEffect(() => {
    async function getTokenBalnce() {
      try {
        const response = await fetch(`${HOST}/auth/getTokenBalnce?address=${publicKey.toBase58()}`, configuration)
        const data = await response.json()

        setBalnce(data.balance)

      } catch (error) {
        console.log(error)
        setBalnce(0)
      }
    };

    if (publicKey) {
      getTokenBalnce()
    }
  }, [connected])

  return (
    <div className='xpCont flex items-center gap-2 border-2 border-[#233753] px-2 sm:px-4 py-1 rounded-2xl'>
      <CiBitcoin className='text-[#667cb0] text-xl' />
      <p className='mb-0 text-base sm:text-xl text-[#667cb0]'>{parseFloat(Balnce).toFixed(2)}</p>
    </div>
  )
}

export default BoopHeader