import { createSlice } from "@reduxjs/toolkit";
import { registerWallet, fetchWallet } from '../redux/userActions'

async function getUserInfo() {
  let theUser = {
    userAuthenticated: false,
    walletConnected: false,
    userData: {}
  }
  return theUser
}
// const initialState = todosData

const initialState = {
  loading: false,
  userInfo: null,
  error: null,
  success: false
}

const userAuthReducer = createSlice({
  name: "user",
  initialState,

  reducers: {},

  extraReducers: {
    // register user
    [registerWallet.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [registerWallet.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.success = true
      state.userInfo = payload
    },
    [registerWallet.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },

    [fetchWallet.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [fetchWallet.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
    },
    [fetchWallet.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    },
  },

});

// export const { addTodos, deleteTodos, updateTodos, filterTodo } = userAuthReducer.actions;
export default userAuthReducer.reducer;
