import React from 'react'
import { AiOutlineLock } from 'react-icons/ai';

const ClaimAmbassador = ({ data }) => {
  // const formattedDate = new Date(data.lastClaimed).toISOString().split('T')[0].split('-').reverse().join('-');
  return (
    <>
      <div className='bg-[#1E293B] p-5 rounded-lg mt-5'>
        <div className='flex justify-between items-center'>
          <h1 className='text-xl fontBold'>Wallet</h1>
          {/* <p className='text-sm text-gray-300 fontBold'>Last claimed : <span>-</span></p> */}
        </div>


        <div className='flex justify-between'>
          <div className="">
            <p className='text-gray-400 mb-4'>Balance</p>
            <p className="text-xl font-bold text-[#FA6002]">{data.boopToclaim} $BOOP</p>

            <p className="text-xl font-bold text-[#FA6002]">{data.solToClaim} $SOL</p>

            <p className="text-white fontBold mt-4">Address</p>
            <p className="text-gray-300 text-sm cursor-pointer font-semibold">{data.address}</p>
          </div>

          <div>
            <button className='button fontBold teext-sm'>Withdraw</button>
          </div>
        </div>
       
      </div>
    </>
  )
}

export default ClaimAmbassador